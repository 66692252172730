/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { createContext, useContext, useState, ReactNode } from 'react';

type CaptureContextType = {
  customerSelfie: string; 
  customerRecording: any;
  setCustomerSelfie: React.Dispatch<React.SetStateAction<any>>;
  setCustomerRecording: React.Dispatch<React.SetStateAction<any>>;
};

const CaptureContext = createContext<CaptureContextType | undefined>(undefined);

export function useCaptureContext() {
  const context = useContext(CaptureContext);
  if (!context) {
    throw new Error('useCaptureContext must be used within a CaptureContextProvider');
  }
  return context;
}

export function CaptureContextProvider({ children }: { children: ReactNode }) {
  const [customerSelfie, setCustomerSelfie] = useState<any>('');
  const [customerRecording, setCustomerRecording] = useState<any>('');

  return (
    <CaptureContext.Provider value={{ customerSelfie,customerRecording, setCustomerSelfie, setCustomerRecording }}>
      {children}
    </CaptureContext.Provider>
  );
}
