/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */

import './EnterYourDetails.css';
import {InputGroup} from "../../../../core/uikit/inputGroup/InputGroup.tsx";
import ButtonReuse from "../../../../core/uikit/button/Button.tsx";
import HeaderContent from "../../components/headerContent/HeaderContent.tsx";
import ContentWrapper from "../../components/contentWrapper/ContentWrapper.tsx";
import {useNavigate} from "react-router-dom";
import {ChangeEvent} from "react";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../core/store";
import ErrorComponent from "../../../../core/containers/errorComponent/ErrorComponent.tsx";
import RouteNames from "../../../../core/routes/routenames.ts";
import {setFName, setLName} from "../../verificationSlice.ts";
import {LoadingState} from "../../../../core/utils/helpers.ts";
import LoaderComponent from "../../components/loaderComponent/LoaderComponent.tsx";

export function EnterYourDetails(){
    const router = useNavigate();
    const {createVerificationLoadingState, vid, createVerificationError, fname, lname} = useSelector((state: RootState) => state.verification);
    const isButtonDisabled: boolean = !fname || !lname;
    const dispatch = useDispatch()
    const routeToEnterBVNPage = () => {
        router(RouteNames.enterBVN);
    }

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.name === 'fname') {
            dispatch<any>(setFName(e.target.value));
        } else if (e.target.name === 'lname') {
            dispatch<any>(setLName(e.target.value));
        }
    }

    return(
        (createVerificationLoadingState === LoadingState.loading) ?
            <LoaderComponent/> :
        (createVerificationLoadingState === LoadingState.error || !vid)?
            <ErrorComponent errorMsg={createVerificationError || "Invalid Link"}/>:
            <ContentWrapper className="enter-details-wrapper fade-in">
            <HeaderContent
                title="Enter your details"
                subTitle="Please enter your details for a quick and secure verification process."
            />
            <section className="bvn-search-input">
                <InputGroup
                    type="text"
                    placeholder="Enter your first name"
                    name="fname"
                    value={fname}
                    label="First name"
                    onChange={onChange}
                />
            </section>

            <section className="bvn-search-input">
                <InputGroup
                    type="text"
                    placeholder="Enter your last name"
                    name="lname"
                    value={lname}
                    label="Last name"
                    onChange={onChange}
                />
            </section>
            <section className="button-container">
                <ButtonReuse
                    isDisabled={isButtonDisabled}
                    onClick={routeToEnterBVNPage}
                    btnStyle="btn-reuse"
                    text="Proceed" />
            </section>
        </ContentWrapper>
    )
}
