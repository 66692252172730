/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import './SelectCountry.css';
import {InputGroup} from "../../../../core/uikit/inputGroup/InputGroup.tsx";
import HeaderContent from "../../components/headerContent/HeaderContent.tsx";
import ContentWrapper from "../../components/contentWrapper/ContentWrapper.tsx";
import useIcon from "../../../../core/hooks/useIcon.ts";
import {useNavigate} from "react-router-dom";
import {ChangeEvent, useState} from "react";
import {getCurrencyIconUrl, LoadingState} from "../../../../core/utils/helpers.ts";
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from "../../../../core/store";
import Routenames from "../../../../core/routes/routenames.ts";
import {setCountry} from "../../verificationSlice.ts";
import ErrorComponent from "../../../../core/containers/errorComponent/ErrorComponent.tsx";
import LoaderComponent from "../../components/loaderComponent/LoaderComponent.tsx";
export function SelectCountry(){
    const __initialCountriesData = [
        {
            name: "Nigeria",
            symbol: "NGN",
            code: 'NG',
        },
        {
            name: "Ghana",
            symbol: "GHS",
            code: 'GH',
        },
        {
            name: "South Africa",
            symbol: "ZAR",
            code: 'ZA',
        },
        {
            name: "United Kingdom",
            symbol: "GBP",
            code: 'GB',
        }
    ]
    const router = useNavigate();
    const {searchIcon} = useIcon();
    const [searchQuery, setSearchQuery] = useState('');
    const [countries, setCountries] = useState(__initialCountriesData);
    const {createVerificationLoadingState, vid, createVerificationError} = useSelector((state : RootState) => state.verification);
    const dispatch = useDispatch()
    const filterCountries = (countryArg: string) => {
        const filteredCountries = __initialCountriesData.filter((country) => {
            return country.name.toLowerCase().includes(countryArg.toLowerCase())
        });
        setCountries(filteredCountries);
    }

    const routeToGetVerifiedPage = () => {
        router(Routenames.getVerified);
    }
    const selectCountry = (country: string) => {
        dispatch(setCountry(country.toLowerCase()));
        routeToGetVerifiedPage();
    }

    const triggerCountrySearch = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(e.target.value);
        if (!e.target.value) return setCountries(__initialCountriesData);
        filterCountries(e.target.value);
    }

    return(
        (createVerificationLoadingState === LoadingState.loading) ?
        <LoaderComponent/> :
        (createVerificationLoadingState === LoadingState.error || !vid)?
        <ErrorComponent errorMsg={createVerificationError || "Invalid Link"}/>:
        <ContentWrapper className="select-country-wrapper fade-in">
                <HeaderContent
                    title="Select your country to get started"
                    subTitle="We’ll securely transfer you to your country’s document verification center."
                />
                <section className="select-country-search-input">
                    <InputGroup
                        rightIcon={searchIcon}
                        type="text"
                        placeholder="Search"
                        name="search"
                        value={searchQuery}
                        onChange={triggerCountrySearch}
                    />
                </section>
                <section className="select-country-list">
                    {
                        countries.length > 0 ? (
                            countries.map((country, index) => (
                                <div onClick={() => selectCountry(country.code)} className="select-country-list-item" key={index}>
                                    <figure className="select-country img-wrapper">
                                        <img className="img select-country" src={getCurrencyIconUrl(country.symbol)} alt=""/>
                                    </figure>
                                    <p className="country-name">{country.name}</p>
                                </div>
                            ))
                        ): (
                            <p className="no-country-found">No country found!</p>
                        )
                    }
                </section>
            </ContentWrapper>
    )
}
