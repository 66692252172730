import './IDReceived.css';
import HeaderContent from "../../components/headerContent/HeaderContent.tsx";
import RouteNames from "../../../../core/routes/routenames.ts";
import {setCurrentStep} from "../../verificationSlice.ts";
import useVerification from "../../../../core/hooks/useVerification.ts";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../core/store";
import {useLocation, useNavigate} from "react-router-dom";
import ButtonReuse from "../../../../core/uikit/button/Button.tsx";
import {useEffect} from "react";
import {persistCurrentStep} from "../../persistenceService.ts";
import SuccessfulIDUpload from '../../../../core/assets/images/successful-id-upload.svg?react';

export default function IDReceived ():JSX.Element{
    const {currentExitRoute, currentEntryPoint} = useVerification();
    const {
        verificationRequirements,
        currentStep,
    } = useSelector((state : RootState) => state.verification);
    const {pathname} = useLocation();
    const router = useNavigate();
    const dispatch = useDispatch();
    const determineNextSteps =  () => {
        if (pathname !== currentExitRoute) return
        if (verificationRequirements.length <= (currentStep + 1)){
            //Trigger API call for credentials submitted
            router(RouteNames.verificationComplete)
        } else {
            persistCurrentStep(currentStep + 1);
            dispatch(setCurrentStep(1));
        }
    }

    useEffect(() => {
        if (currentExitRoute !== pathname && currentExitRoute.length > 0){
            router(currentEntryPoint)
        }
    }, [currentExitRoute, currentEntryPoint]);

    const proceed = () => {
        determineNextSteps();
    }

    return (
        <div className="id-received-container">
            <div style={{marginBottom: "3rem", marginTop: "40%", width: '14rem', height: '14rem'}}>
                <SuccessfulIDUpload style={{width: '100%', height: '100%', objectFit: "contain"}}/>
            </div>
            <HeaderContent
                title="Great Job"
                subTitle="We’ve received your documents, you can go ahead and proceed, we’ll let you know when it’s complete."/>

            <section style={{width: '100%', marginTop: 'auto'}}>
                <ButtonReuse
                    style={{width: '100%'}}
                    onClick={proceed}
                    btnStyle="btn-reuse"
                    text="Proceed" />
            </section>
        </div>
    )
}
