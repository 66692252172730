/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */

import ContentWrapper from "../contentWrapper/ContentWrapper.tsx";
import './DropFeedback.css'
import ButtonReuse from "../../../../core/uikit/button/Button.tsx";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../core/store";
import {ChangeEvent, useEffect, useState} from "react";
import {sendFeedbackThunk} from "../../verificationThunks.ts";
import {LoadingState} from "../../../../core/utils/helpers.ts";
import useIcon from "../../../../core/hooks/useIcon.ts";
import {useNavigate} from "react-router-dom";
import routenames from "../../../../core/routes/routenames.ts";

export default function DropFeedback() {
    const router = useNavigate();
    const {vid, sendFeedbackLoadingState, sendFeedbackError} = useSelector((state: RootState) => state.verification);
    const [feedBack, setFeedBack] = useState('');
    const dispatch = useDispatch();
    const {errorExclamationIcon} = useIcon()
    const [error, setError] = useState<string>("");
    const MIN_FEEDBACK_LENGTH = 3;
    const MAX_FEEDBACK_LENGTH = 100;
    const isButtonDisabled: boolean = (!feedBack || feedBack.length > MAX_FEEDBACK_LENGTH || feedBack.length < MIN_FEEDBACK_LENGTH);
    const handleSubmitFeedback = () => {
        dispatch<any>(sendFeedbackThunk({vid, feedback: feedBack}))
    }

    const onChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        setError(e.target.value.length <= MAX_FEEDBACK_LENGTH ? '' : "You're allowed only 100 characters")
        setFeedBack(e.target.value);
    }

    useEffect(() => {
        if (sendFeedbackLoadingState === LoadingState.error){
            setError(sendFeedbackError || 'Error sending feedback');
        } else if (sendFeedbackLoadingState === LoadingState.successful){
            router(routenames.feedbackComplete)
        }
    }, [sendFeedbackLoadingState]);
    return (
        <ContentWrapper className="drop-feedback-wrapper fade-in">
            <header>Give us feedback</header>
            <p>Tell us what you think we can improve on</p>

            <section className="form">
                <p className="form__header">Enter Feedback</p>

                <section>
                     <textarea
                         onChange={onChange}
                         rows={8}
                         value={feedBack}
                         className="textarea__has-error"
                         placeholder="Type anything"></textarea>

                    <footer className="error__and__char__count">
                        <div className="error">
                            {
                                error && (
                                    <>
                                        <figure>
                                            <img src={errorExclamationIcon} alt="error"/>
                                        </figure>
                                        <span>{error}</span>
                                    </>
                                )
                            }
                        </div>

                        <p className="form__footer">
                            {feedBack.length} / {MAX_FEEDBACK_LENGTH}
                        </p>
                    </footer>
                </section>

                <ButtonReuse
                    loading={sendFeedbackLoadingState === LoadingState.loading}
                    isDisabled={isButtonDisabled}
                    onClick={() => handleSubmitFeedback()}
                    btnStyle="btn-reuse"
                    text="Submit" />
            </section>

        </ContentWrapper>
    );
}
