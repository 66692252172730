import { configureStore } from '@reduxjs/toolkit';
import VerificationReducer from '../../features/ng-verification/verificationSlice.ts';
import ToastReducer from "../../features/ng-verification/toastSlice.ts"
export const store = configureStore({
    devTools: (process.env.NODE_ENV !== 'production'),
    reducer: {
        verification: VerificationReducer,
        toast: ToastReducer,
    },
});

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
