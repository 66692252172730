/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import HeaderContent from '../../components/headerContent/HeaderContent';
import {useLocation, useNavigate} from "react-router-dom";
import ContentWrapper from "../../components/contentWrapper/ContentWrapper.tsx";
import {useCaptureContext} from '../../../../core/context/CaptureContextProvider.tsx';
import {useEffect, useState} from "react";
import {blobToDataURL, dataURLtoFile, LoadingState} from "../../../../core/utils/helpers.ts";
import RouteNames from "../../../../core/routes/routenames.ts";
import {setCurrentStep} from "../../verificationSlice.ts";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../core/store";
import useVerification from "../../../../core/hooks/useVerification.ts";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import {pollSelfieVerificationStatusThunk, sendCustomerRecordingThunk} from "../../verificationThunks.ts";
import ErrorComponent from "../../../../core/containers/errorComponent/ErrorComponent.tsx";
import {useTranslation} from "react-i18next";
import useImages from "../../../../core/hooks/useImages.ts";

function CaptureSuccess() {
    const {pathname} = useLocation();
    const {verificationRequirements, currentStep, timestamp, vid, sendCustomerRecordingLoadingState, pollSelfieVerificationLoadingState} = useSelector((state:RootState) =>state.verification);
    const { customerRecording } = useCaptureContext();
    const dispatch = useDispatch();
    const router = useNavigate();
    const {currentExitRoute, currentEntryPoint} = useVerification();
    const [videoSubmitted, setVideoSubmitted] = useState(false);
    const {t} = useTranslation();
    const { verificationProcessingGIF} = useImages();

    const determineNextSteps =  () => {
        if (pathname !== currentExitRoute) return
        if (verificationRequirements.length <= (currentStep + 1)){
            router(RouteNames.verificationComplete)
        } else {
            dispatch(setCurrentStep(1));
        }
    }

    useEffect(() => {
        if (currentExitRoute !== pathname && currentExitRoute.length > 0){
            router(currentEntryPoint)
        }
    }, [currentExitRoute, currentEntryPoint]);

    useEffect(() => {
        // Call API
        async function handleSubmitVideo(){
            const res = await fetch(customerRecording);
            const blob = await res.blob();
            const dataUrl = await blobToDataURL(blob);
            const videoFile = dataURLtoFile(dataUrl, 'customerVideo');
            // const videoFile =  blobToFile(blob, 'customerVideo');

            //Download video
            // downloadVideo(videoFile);

            const data = new FormData();

            // Check if vid and timestamp are available
            if (!vid) return;
            if (!timestamp) return;

            data.append('vid', vid)
            data.append('frame', timestamp);
            data.append('video',videoFile);

            dispatch<any>(sendCustomerRecordingThunk(data))  
        }

        if (customerRecording && !videoSubmitted) {
            handleSubmitVideo();
            setVideoSubmitted(true);
        }
    }, [customerRecording]);


    useEffect(() => {
        if (sendCustomerRecordingLoadingState === LoadingState.successful){
            // Poll API
            const intervalID: NodeJS.Timeout = setInterval(() =>{
                dispatch<any>(pollSelfieVerificationStatusThunk({
                    vid
                }))
            }, 5000);

            return () => clearInterval(intervalID);
        }
    }, [sendCustomerRecordingLoadingState]);

    useEffect(() => {
        if (pollSelfieVerificationLoadingState === LoadingState.successful){
            determineNextSteps();
        } else if (pollSelfieVerificationLoadingState === LoadingState.error){
                router(RouteNames.verificationFailed);
        }
    }, [pollSelfieVerificationLoadingState])

  return (
      (customerRecording)?
      <ContentWrapper className="fade-in">
      <div className="capture-container" style={{ marginTop: '50px' }}>
        <figure style={{marginTop: '2rem', width: '30rem', height: '30rem'}}>
            <img
                style={{width: '100%', height: '100%'}}
                src={verificationProcessingGIF}
                alt="verification__processing"
            />
        </figure>

        <div style={{marginTop: "2rem"}}>
          <HeaderContent
            title={t('captureSuccess.title')}
            subTitle={t('captureSuccess.subTitle')}
          />
        </div>
      </div>
    </ContentWrapper>:
    <ErrorComponent errorMsg={t('commonErrorText')}/>
  );
}

export default CaptureSuccess;
