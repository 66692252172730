/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */

import './EnterYourBVN.css';
import {InputGroup} from "../../../../core/uikit/inputGroup/InputGroup.tsx";
import ButtonReuse from "../../../../core/uikit/button/Button.tsx";
import HeaderContent from "../../components/headerContent/HeaderContent.tsx";
import ContentWrapper from "../../components/contentWrapper/ContentWrapper.tsx";
import {useLocation, useNavigate} from "react-router-dom";
import {ChangeEvent, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../core/store";
import {setCurrentStep, setIDNumber} from "../../verificationSlice.ts";
import ErrorComponent from "../../../../core/containers/errorComponent/ErrorComponent.tsx";
import {AnyAction, Dispatch} from "@reduxjs/toolkit";
import useVerification from "../../../../core/hooks/useVerification.ts";
import RouteNames from "../../../../core/routes/routenames.ts";
import {validateIdentifierThunk} from "../../verificationThunks.ts";
import {LoadingState} from "../../../../core/utils/helpers.ts";
import {getVerificationIdentifierFromStorage, persistCurrentStep} from "../../persistenceService.ts";
import {useTranslation} from "react-i18next";

export function EnterYourBVN(){
    const {t} = useTranslation();
    const router = useNavigate();
    const {pathname} = useLocation();
    const dispatch:Dispatch<AnyAction> = useDispatch();
    const {verificationRequirements, currentStep, vid, id_number, fname, lname, validateIdentifierLoadingState, validateIdentifierError} = useSelector((state : RootState) => state.verification);
    const {currentExitRoute, currentEntryPoint} = useVerification();
    const [error, setError] = useState<string>('');
    const MAX_BVN_LENGTH = 11;
    const isButtonDisabled: boolean = (!id_number || id_number.length !== MAX_BVN_LENGTH);

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        setError(e.target.value.length <= MAX_BVN_LENGTH ? '' : t('EnterYourBVN.maxLengthError'))
        dispatch(setIDNumber(e.target.value));
    }


    const determineNextSteps =  () => {
        if (pathname !== currentExitRoute) return
        if (verificationRequirements.length <= (currentStep + 1)){
            //Trigger API call for credentials submitted
            router(RouteNames.verificationComplete)
        } else {
            persistCurrentStep(currentStep + 1)
            dispatch(setCurrentStep(1));
        }
    }

    useEffect(() => {
        if (currentExitRoute !== pathname && currentExitRoute.length > 0){
            router(currentEntryPoint)
        }
    }, [currentExitRoute, currentEntryPoint]);

    const handleBVNSubmission = () => {
        dispatch<any>(validateIdentifierThunk({
            vid,
            id_number,
            fname,
            lname,
        }))
    }


    useEffect(() => {
        if (validateIdentifierLoadingState === LoadingState.error){
            setError(validateIdentifierError || 'Invalid BVN');
        } else if (validateIdentifierLoadingState === LoadingState.successful){
            determineNextSteps();
        }
    }, [validateIdentifierLoadingState]);


    return(
        ((vid || getVerificationIdentifierFromStorage() ) && fname && lname) ?
        <ContentWrapper className="enter-bvn-wrapper fade-in">
            <HeaderContent
                title={t('EnterYourBVN.headerContentTitle')}
                subTitle={t('EnterYourBVN.headerContentSubTitle')}
            />
            <section className="bvn-search-input">
                <InputGroup
                    type="text"
                    placeholder={t('EnterYourBVN.inputPlaceHolder')}
                    name="bvn"
                    value={id_number}
                    label="Enter your BVN"
                    onChange={onChange}
                    isError={error.length > 0}
                    errorMessage={error}
                />
            </section>
            <section className="button-container">
                <ButtonReuse
                    loading={validateIdentifierLoadingState === LoadingState.loading}
                    isDisabled={isButtonDisabled}
                    onClick={handleBVNSubmission}
                    btnStyle="btn-reuse"
                    text={t('EnterYourBVN.btnText')} />
            </section>
        </ContentWrapper>:
        <ErrorComponent errorMsg={t('commonErrorText')}/>
    )
}
