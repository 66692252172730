import ContentWrapper from "../contentWrapper/ContentWrapper.tsx";
import './FixABlankScreen.css';
import GetHelpComponent from "./GetHelpComponent.tsx";
import RouteNames from "../../../../core/routes/routenames.ts";
import InfoImage from '../../../../core/assets/images/info.svg?react';

export default function FixABlankScreen() {
    return (
        <ContentWrapper className="fix-a-blank-screen-wrapper fade-in">
            <figure>
                <InfoImage />
            </figure>
            <header>Steps to fix blank screen</header>
            <ol className="list-of-questions">
               <li> Ensure you are using the latest Chrome browser and it is set as your default browser, click <a target="_blank" href="https://www.androidpolice.com/make-google-chrome-default-any-device/">here</a> for instructions on how to set Chrome as the default browser on your Android phone.</li>
               <li> Grant Chrome all the necessary permissions required, click <a target="_blank" href="https://vuspex.com/support/user-guide/how-to-enable-camera-permissions-on-chrome-for-android/">here</a> to learn how to do that on your Android device. </li>
               <li> Restart your Chrome on your phone by closing the app and re-opening it. </li>
               <li> Restart your Roqqu app and begin the KYC process again. </li>
               <li> Click on "allow" when prompted for camera access by Chrome. </li>
               <li>If the issue persists after doing all these steps, contact Roqqu support for assistance. </li>
            </ol>

            <GetHelpComponent
                text="Still having issues?"
                action="Drop a feedback"
                routePage={RouteNames.dropFeedback}
            />
        </ContentWrapper>
    );
}
