import {newHttpClient} from "../../core/utils/httpClient.ts";
import verificationUrls from "./verificationUrls.ts";
import {videoUploadAxiosInstance} from "../../core/utils/axiosInstance.ts";

const verificationServiceClient = newHttpClient();
const videoServiceClient = newHttpClient(videoUploadAxiosInstance);



export const createVerificationIdentifier = async (data: object) => {
    return await verificationServiceClient.post!(verificationUrls.createVerificationIdentifier, data);
}
export const validateIdentifier = async (data: object) => {
    return await verificationServiceClient.post!(verificationUrls.validateIdentifier, data);
}
export const sendCustomerRecording = async (data: object) => {
    const options = {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    }
    return await videoServiceClient.post!(verificationUrls.sendCustomerRecording, data, options);
}

export const pollSelfieVerificationStatus = async (queryParams: object) => {
    return await verificationServiceClient.get!(verificationUrls.pollSelfieVerificationStatus, queryParams)
}

export const checkAllowedIDs = async (queryParams: object) => {
    return await verificationServiceClient.get!(verificationUrls.checkAllowedIDs, queryParams);
}

export const sendCustomerID = async (data: object) => {
    const options = {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    }
    return await videoServiceClient.post!(verificationUrls.sendCustomerID, data, options);
}


export const sendFeedback = async (data: object) => {
    return await verificationServiceClient.post!(verificationUrls.sendFeedback, data);
}


