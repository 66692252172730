export const sessionKeys = {
    VERIFICATION_IDENTIFER: 'rqvid',
    META_DATA: 'rqmd',
    COUNTRY: 'rqco',
    REQUIRED_FIELDS: 'rqrf',
    ALLOWED_IDS: 'rqai',
    CURRENT_STEP: 'rqcs'
}

export const persistVerificationIdentifier = (identifier: string) => {
  sessionStorage.setItem(sessionKeys.VERIFICATION_IDENTIFER, identifier);
}

export const getVerificationIdentifierFromStorage = () => {
    return sessionStorage.getItem(sessionKeys.VERIFICATION_IDENTIFER);
}

export const persistMetaData = (metaData: string) => {
    sessionStorage.setItem(sessionKeys.META_DATA, metaData);
}

export const getMetaDataFromStorage = () => {
    return sessionStorage.getItem(sessionKeys.META_DATA);
}

export const persistCountry = (country: string) => {
    sessionStorage.setItem(sessionKeys.COUNTRY, country);
}

export const getCountryFromStorage = () => {
    return sessionStorage.getItem(sessionKeys.COUNTRY);
}

export const persistRequiredFields = (requiredFields: string) => {
    sessionStorage.setItem(sessionKeys.REQUIRED_FIELDS, requiredFields);
}

export const getRequiredFieldsFromStorage = () => {
    return sessionStorage.getItem(sessionKeys.REQUIRED_FIELDS);
}

export const persistAllowedIDs = (allowedIDs: string[]) => {
    const stringified = JSON.stringify(allowedIDs);
    sessionStorage.setItem(sessionKeys.ALLOWED_IDS, stringified);
}

export const getAllowedIDsFromStorage = () => {
    const stringifiedData = sessionStorage.getItem(sessionKeys.ALLOWED_IDS);
    return JSON.parse(stringifiedData!);
}


export const persistCurrentStep = (currentStep: number) => {
    sessionStorage.setItem(sessionKeys.CURRENT_STEP, currentStep.toString());
}

export const getCurrentStepFromStorage = () => {
    const stringifiedData = sessionStorage.getItem(sessionKeys.CURRENT_STEP);
    if (!stringifiedData) return null;
    return parseInt(stringifiedData);
}


export const cleanUpStorage = () => {
    sessionStorage.clear();
    localStorage.clear();
}
