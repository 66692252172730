/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { createAsyncThunk } from '@reduxjs/toolkit';
import {
    checkAllowedIDs,
    createVerificationIdentifier,
    pollSelfieVerificationStatus, sendCustomerID,
    sendCustomerRecording, sendFeedback,
    validateIdentifier
} from "./verificationService.ts";
import {getCustomMessageFromError} from "../../core/utils/helpers.ts";



export const createVerificationIdentifierThunk = createAsyncThunk(
    'verification/createVerificationIdentifier',
    async (data: object, { rejectWithValue }) => {
        try {
            const res = await createVerificationIdentifier(data);
            if (res.data.status !== 'success') throw new Error(res.data.message);
            return res.data.data;
        } catch (e: any) {
            if (e.response){
                const msg = getCustomMessageFromError(e.response.data);
                return rejectWithValue(msg);
            }
            const msg = getCustomMessageFromError(e as Error);
            return rejectWithValue(msg);
        }
    })

export const validateIdentifierThunk = createAsyncThunk(
    'verification/validateIdentifierThunk',
    async (data: object, { rejectWithValue }) => {
        try {
            const res = await validateIdentifier(data);
            if (res.data.status !== 'success') throw new Error(res.data.message);
            return res.data.data;
        } catch (e: any) {
            if (e.response){
                const msg = getCustomMessageFromError(e.response.data);
                return rejectWithValue(msg);
            }
            const msg = getCustomMessageFromError(e as Error);
            return rejectWithValue(msg);
        }
    })

export const sendCustomerRecordingThunk = createAsyncThunk(
    'verification/sendCustomerRecording',
    async (data: FormData, { rejectWithValue }) => {
        try {
            const res = await sendCustomerRecording(data);
            if (res.data.status !== 'success') throw new Error(res.data.message);
            return res.data.data;
        } catch (e:any) {
            if (e.response){
                const msg = getCustomMessageFromError(e.response.data);
                return rejectWithValue(msg);
            }
            const msg = getCustomMessageFromError(e as Error);
            return rejectWithValue(msg);
        }
})

export const pollSelfieVerificationStatusThunk = createAsyncThunk(
    'verification/pollSelfieVerificationStatus',
    async (data: object, { rejectWithValue }) => {
        try {
            const res = await pollSelfieVerificationStatus(data);
            if (res.data.data.status.includes('fail')) throw new Error(res.data.data.status);
            return res.data.data;
        } catch (e:any) {
            if (e.response){
                const msg = getCustomMessageFromError(e.response.data);
                return rejectWithValue(msg);
            }
            const msg = getCustomMessageFromError(e as Error);
            return rejectWithValue(msg);
        }
})


export const checkAllowedIDsThunk = createAsyncThunk(
    'verification/checkAllowedIDs',
    async (data: object, { rejectWithValue }) => {
        try {
            const res = await checkAllowedIDs(data);
            if (res.data.status !== 'success') throw new Error(res.data.message);
            return res.data.data;
        } catch (e:any) {
            if (e.response){
                const msg = getCustomMessageFromError(e.response.data);
                return rejectWithValue(msg);
            }
            const msg = getCustomMessageFromError(e as Error);
            return rejectWithValue(msg);
        }
    })


export const sendCustomerIDThunk = createAsyncThunk(
    'verification/sendCustomerID',
    async (data: object, { rejectWithValue }) => {
        try {
            const res = await sendCustomerID(data);
            if (res.data.status !== 'success') throw new Error(res.data.message);
            return res.data.data;
        } catch (e:any) {
            if (e.response){
                const msg = getCustomMessageFromError(e.response.data);
                return rejectWithValue(msg);
            }
            const msg = getCustomMessageFromError(e as Error);
            return rejectWithValue(msg);
        }
    })

export const sendFeedbackThunk = createAsyncThunk(
    'verification/sendFeedback',
    async (data: object, { rejectWithValue }) => {
        try {
            const res = await sendFeedback(data);
            if (res.data.status !== 'success') throw new Error(res.data.message);
            return res.data.data;
        } catch (e: any) {
            if (e.response){
                const msg = getCustomMessageFromError(e.response.data);
                return rejectWithValue(msg);
            }
            const msg = getCustomMessageFromError(e as Error);
            return rejectWithValue(msg);
        }
    })
