import './Button.css';
import useIcon from "../../hooks/useIcon.ts";
import {useTranslation} from "react-i18next";

function ButtonReuse({
     text = '',
     onClick = () => {},
     loading = false,
     bgColor = '',
     btnStyle = '',
     style = {},
     children = '',
     isDisabled = false,
     }) {
    const {t} = useTranslation();
    const { loadingCircle } = useIcon();

    const buttonDisabled = loading || isDisabled;
    return (
        <>
            <button
                style={{ backgroundColor: bgColor || '', ...style }}
                className={`reuseable-btn-wrap ${btnStyle} ${buttonDisabled ? 'disabled' : ''}`}
                onClick={onClick}
                disabled={buttonDisabled}
            >
                {loading ? (
                    <img src={loadingCircle} className="spin-loader" alt="" />
                ) : (
                    <>
                        {children}
                        <span>{text === null ? t('buttonComponent.defaultText') : text}</span>
                    </>
                )}
            </button>
        </>
    );
}

export default ButtonReuse;
