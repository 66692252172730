import {CSSProperties} from "react";

type Props = {
  children: React.ReactNode;
  className?: string;
  style?: CSSProperties;
};
export default function ContentWrapper({
  children,
  className = '',
  style = { padding: '4rem 2.6rem', width: '100%' },
}: Props) {
  return (
    <div
      style={style}
      className={className}
    >
      {children}
    </div>
  );
}
