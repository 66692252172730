import './TakeSelfie.css';
import {useSelector} from "react-redux";
import {RootState} from "../../../../core/store";
import InstructionComponent from "../../components/instructionComponent/InstructionComponent.tsx";
import ErrorComponent from "../../../../core/containers/errorComponent/ErrorComponent.tsx";
import ModalLayout from "../../../../core/uikit/modalLayout/ModalLayout.tsx";
import {useState} from "react";
import useIcon from "../../../../core/hooks/useIcon.ts";
import ButtonReuse from "../../../../core/uikit/button/Button.tsx";
import { useNavigate } from 'react-router-dom';
import RouteNames from '../../../../core/routes/routenames.ts';
import takeSelfieGIF from '../../../../core/assets/gif/how-to-take-selfie.gif';
import TakeSelfieImg from '../../../../core/assets/images/take-selfie.svg?react';

export function TakeSelfie(){
    const {vid} = useSelector((state : RootState) => state.verification);
    const router = useNavigate();
    const {cancelIcon} = useIcon();
    const [modalVisible, setModalVisible] = useState(false);

    const startSelfie = () => {
        router(RouteNames.captureFace);
    }

    const pageDetails = {
        headerContentTitle: "Take a selfie",
        headerContentSubTitle: "Snap a quick selfie to verify it's really you. Please make sure you're wearing a shirt. It only takes a second!",
        buttonReuseText: "Take Selfie",
        action: startSelfie,
        img: <TakeSelfieImg />,
        imgWrapperStyle: {
            width: '15rem',
        }
    }

    const additionalBtnDetails = {
        text: 'Watch a how to video',
        action: () => setModalVisible(!modalVisible)
    }

    const getHelp = {
        text: "Having Issues?",
        action: "Get Help",
    }
    return(
        (vid) ?
            <>
                <InstructionComponent
                    pageDetails={pageDetails}
                    additionalBtn
                    additionalBtnDetails={additionalBtnDetails}
                    getHelp = {getHelp}
                />
                <ModalLayout
                    width="48.7rem"
                    visible={modalVisible}
                    onClose={() => setModalVisible(!modalVisible)}>
                        <section className="take-selfie-wrapper">
                        <figure onClick={() => setModalVisible(!modalVisible)} className="icon-wrapper">
                            <img src={cancelIcon} alt="Cancel Icon"/>
                        </figure>
                        <header>
                            Take a selfie. It’s that easy
                        </header>
                        <figure className="main-content-img">
                            <img src={takeSelfieGIF} alt="take-selfie-gif"/>
                        </figure>
                        <div className="main-content">
                            <ButtonReuse
                                onClick={startSelfie}
                                btnStyle="btn-reuse"
                                text="Take Selfie" />
                        </div>
                    </section>
                </ModalLayout>
            </> :
        <ErrorComponent errorMsg="Invalid link"/>
    )
}
