/* eslint-disable @typescript-eslint/no-explicit-any */
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import { useEffect, useState } from 'react';
import useIcon from './useIcon.ts';
import { EntryPointsForRoutes, ExitPointsForRoutes } from '../routes';
import {
  VerificationRequirement,
  VerificationRequirementsRefType,
} from '../types';
import { getCurrentStepFromStorage } from '../../features/ng-verification/persistenceService.ts';

const getCurrentVerificationPage = (
  requirements: string[],
  currentStep: number,
) => {
  return requirements[currentStep];
};

// Define the main function to use verification
export default function useVerification() {
  // Use the useIcon function to get icons
  const { bankIcon, cameraIcon } = useIcon();
  // Initialize state variables
  const [currentExitRoute, setCurrentExitRoute] = useState('');
  const [currentEntryPoint, setCurrentEntryPoint] = useState('');
  const [pageDetails, setPageDetails] = useState<
    Array<VerificationRequirement>
  >([]);

  // Create a reference for verification requirements
  const verificationRequirementsRef: VerificationRequirementsRefType = {
    bvn: {
      title: 'Bank verification details',
      description: 'To check if your personal information is correct',
      icon: bankIcon,
      iconAlt: 'bank icon',
    },
    selfie: {
      title: 'Selfie of yourself',
      description: 'We use this to verify that it is really you',
      icon: cameraIcon,
      iconAlt: 'camera icon',
    },
    id: {
      title: 'Government issued ID',
      description: 'To check if your personal information is correct',
      icon: bankIcon,
      iconAlt: 'bank icon',
    },
  };

  // Get verification requirements and current step from Redux state
  const { verificationRequirements, currentStep } = useSelector(
    (state: RootState) => state.verification,
  );

  // Update page details when verification requirements change
  useEffect(() => {
    setPageDetails(
      verificationRequirements.map(
        (requirement: string) => verificationRequirementsRef[requirement],
      ),
    );
  }, [verificationRequirements]);

  // Update exit and entry points based on the current step
  useEffect(() => {
    const currentStepData = getCurrentStepFromStorage() ?? currentStep;
    setCurrentExitRoute(
      ExitPointsForRoutes[
        getCurrentVerificationPage(verificationRequirements, currentStepData)
      ],
    );
    setCurrentEntryPoint(
      EntryPointsForRoutes[
        getCurrentVerificationPage(verificationRequirements, currentStepData)
      ],
    );
  }, [currentStep, verificationRequirements]);

  // Return the results as an object
  return {
    currentExitRoute,
    currentEntryPoint,
    pageDetails,
  };
}
