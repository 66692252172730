import captureCircleFace from '../assets/images/capture-circle-face.svg';
import captureCircle from '../assets/images/capture-circle.svg';
import errorGif from '../assets/images/error-gif.gif';
import errorFigure from '../assets/images/error-figure.svg';
import enterBVNDoc from '../assets/images/enter-bvn-doc.svg';
import smileForCamera from '../assets/images/smile-for-camera.svg';
import govtID from '../assets/images/govt-id.svg';
import captureBox from '../assets/images/capture-box.svg';
import selfiePlaceholder from '../assets/images/selfie-placeholder.svg';
import roqquQRCodeLogo from '../assets/images/qrLogo.svg';
import loadingGif from '../assets/images/loading.gif';
import uploadID from '../assets/images/upload-id.svg';
import shutter from '../assets/images/shutter.svg';
import getStarted from '../assets/images/get-started.svg';
import uploadGovtID from '../assets/images/uploadGovtID.svg';
import takeSelfie from '../assets/images/take-selfie.svg';
import selectCaptureType from '../assets/images/select-capture-type.svg';
import successfulIDUpload from '../assets/images/successful-id-upload.svg';
import selectOfficialDocument from '../assets/images/selectOfficialDocument.svg';
import failedVerification from '../assets/images/failed-verification-1.svg';
import infoImage from '../assets/images/info.svg';
import feedbackTick from '../assets/images/feedback__tick.svg';
import verificationProcessing from '../assets/images/verification__processing.svg';
import docProcessing from '../assets/gif/doc__processing.gif';
import verificationProcessingGIF from '../assets/gif/verification-processing.gif';
import captureSuccess from '../assets/images/capture-success.svg';

const images = {
  captureCircleFace,
  captureCircle,
  errorGif,
  enterBVNDoc,
  smileForCamera,
  govtID,
  captureBox,
  selfiePlaceholder,
  roqquQRCodeLogo,
  loadingGif,
  uploadID,
  shutter,
  getStarted,
  uploadGovtID,
  takeSelfie,
  selectCaptureType,
  successfulIDUpload,
  selectOfficialDocument,
  failedVerification,
  errorFigure,
  infoImage,
  feedbackTick,
  verificationProcessing,
  docProcessing,
  verificationProcessingGIF,
  captureSuccess,
};

export default function useImages() {
  return images;
}
