import {useEffect, useRef, useState} from "react";
import VideoCapture from "./videoCapture";
import HeaderContent from "../headerContent/HeaderContent.tsx";
import "../../pages/capture/Capture.css"
import { useVideoTestContext } from "../../../../core/context/VideoTestContextProvider.tsx";
import GetHelpComponent from "../getHelpComponent/GetHelpComponent.tsx";
import routenames from "../../../../core/routes/routenames.ts";


export default function CaptureFace() {
    const [modelsLoaded, setModelsLoaded] = useState<boolean>(false);
    const {step, error, message, setStep} = useVideoTestContext()
    const [animate, setAnimate] = useState<string | null>()
    const svgGroupRef = useRef<SVGGElement | null>(null);
    const paths: string[] = [
        'M130.001 1.82617V28.1',
        'M141.43 28.896L143.473 2.78125',
        'M152.721 31.2804L156.808 5.58984',
        'M163.772 35.259L169.864 10.2578',
        'M174.468 40.7203L182.464 16.7266',
        'M184.667 47.6656L194.489 24.918',
        'M194.251 55.9915L205.819 34.7285',
        'M203.141 65.6147L216.296 46.1016',
        'M211.236 76.4313L225.841 58.8535',
        'M218.439 88.3103L234.353 72.8535',
        'M224.67 101.114L241.695 87.9902',
        'M229.868 114.741L247.826 104.057',
        'M233.956 129.034L252.668 120.895',
        'M236.933 143.799L256.16 138.338',
        'M238.719 158.885L258.264 156.154',
        'M239.313 174.156H258.978',
        'M238.719 189.428L258.264 192.159',
        'M236.933 204.514L256.16 209.975',
        'M233.956 219.281L252.668 227.421',
        'M229.868 233.57L247.826 244.255',
        'M224.67 247.199L241.695 260.323',
        'M218.439 260.004L234.353 275.461',
        'M211.236 271.881L225.841 289.459',
        'M203.141 282.699L216.296 302.212',
        'M194.251 292.322L205.819 313.585',
        'M184.667 300.648L194.489 323.396',
        'M174.468 307.594L182.464 331.587',
        'M163.772 313.082L169.864 338.057',
        'M152.721 317.031L156.808 342.722',
        'M141.43 319.418L143.473 345.533',
        'M130.001 320.213V346.487',
        'M116.527 345.533L118.571 319.418',
        'M103.193 342.722L107.281 317.031',
        'M90.1367 338.057L96.2284 313.082',
        'M77.5361 331.587L85.5327 307.594',
        'M65.5117 323.396L75.3338 300.648',
        'M54.1816 313.585L65.7499 292.322',
        'M43.7051 302.212L56.8608 282.699',
        'M34.1611 289.459L48.7653 271.881',
        'M25.6475 275.461L41.5613 260.004',
        'M18.3057 260.323L35.3307 247.199',
        'M12.1748 244.255L30.1324 233.57',
        'M7.33301 227.421L26.0446 219.281',
        'M3.84082 209.975L23.0683 204.514',
        'M1.7373 192.159L21.2823 189.428',
        'M1.02344 174.156H20.6875',
        'M1.7373 156.154L21.2823 158.885',
        'M3.84082 138.338L23.0683 143.799',
        'M7.33301 120.895L26.0446 129.034',
        'M12.1748 104.057L30.1324 114.741',
        'M18.3057 87.9902L35.3307 101.114',
        'M25.6475 72.8535L41.5613 88.3103',
        'M34.1611 58.8535L48.7653 76.4313',
        'M43.7051 46.1016L56.8608 65.6147',
        'M54.1816 34.7285L65.7499 55.9915',
        'M65.5117 24.918L75.3338 47.6656',
        'M77.5361 16.7266L85.5327 40.7203',
        'M90.1367 10.2578L96.2284 35.259',
        'M103.193 5.58984L107.281 31.2804',
        'M116.527 2.78125L118.571 28.896'
    ]
    const shortPaths: string[] =  [
        'M 130.381 17.999 L 130.001 28.1',
        'M 141.43 28.896 L 143.213 20.198',
        'M 152.721 31.28 L 154.53 22.29',
        'M 163.772 35.259 L 166.856 26.776',
        'M 174.468 40.72 L 177.709 31.976',
        'M 184.667 47.666 L 188.544 38.315',
        'M 194.251 55.992 L 198.735 46.583',
        'M 203.141 65.615 L 208.889 56.718',
        'M 211.236 76.431 L 217.43 67.623',
        'M 218.439 88.31 L 225.614 80.734',
        'M 224.67 101.114 L 232.762 94.553',
        'M 229.868 114.741 L 239.18 110.113',
        'M 233.956 129.034 L 242.878 124.484',
        'M 236.933 143.799 L 246.086 140.758',
        'M 238.719 158.885 L 249.263 156.782',
        'M 239.313 174.156 L 249.845 173.887',
        'M 238.719 189.428 L 249.405 190.215',
        'M 236.933 204.514 L 246.443 206.905',
        'M 233.956 219.281 L 244.001 223.25',
        'M 229.868 233.57 L 239.61 238.424',
        'M 224.67 247.199 L 233.635 253.383',
        'M 218.439 260.004 L 226.624 266.683',
        'M 211.236 271.881 L 217.451 279.246',
        'M 203.141 282.699 L 209.386 291.324',
        'M 194.251 292.322 L 199.082 301.061',
        'M 184.667 300.648 L 188.915 309.002',
        'M 174.468 307.594 L 178.368 316.945',
        'M 163.772 313.082 L 166.348 322.696',
        'M 152.721 317.031 L 154.63 328.42',
        'M 141.43 319.418 L 142.638 331.086',
        'M 130.001 320.213 L 129.886 332.398',
        'M 117.34 331.02 L 118.571 319.418',
        'M 105.164 327.521 L 107.281 317.031',
        'M 93.403 322.627 L 96.228 313.082',
        'M 81.525 316.556 L 85.533 307.594',
        'M 70.829 309.178 L 75.334 300.648',
        'M 60.489 300.947 L 65.75 292.322',
        'M 50.846 290.943 L 56.861 282.699',
        'M 42.273 278.684 L 48.765 271.881',
        'M 33.94 266.628 L 41.561 260.004',
        'M 27.505 251.819 L 35.331 247.199',
        'M 21.001 238.326 L 30.132 233.57',
        'M 14.964 223.379 L 26.045 219.281',
        'M 11.857 206.931 L 23.068 204.514',
        'M 10.683 190.272 L 21.282 189.428',
        'M 10.408 174.156 L 20.688 174.156',
        'M 12.177 156.831 L 21.282 158.885',
        'M 14.21 141.473 L 23.068 143.799',
        'M 17.533 125.064 L 26.045 129.034',
        'M 22.083 109.84 L 30.132 114.741',
        'M 27.656 95.244 L 35.331 101.114',
        'M 34.712 81.607 L 41.561 88.31',
        'M 42.019 69.761 L 48.765 76.431',
        'M 50.993 57.836 L 56.861 65.615',
        'M 60.549 48.31 L 65.75 55.992',
        'M 71.078 39.984 L 75.334 47.666',
        'M 82.344 32.966 L 85.533 40.72',
        'M 93.543 26.559 L 96.228 35.259',
        'M 104.616 22.179 L 107.281 31.28',
        'M 117.413 19.103 L 118.571 28.896',
    ]
    const totalSteps: number = 5;
    const totalPaths: number = paths.length;
    const pathsPerStep: number = Math.ceil(totalPaths / totalSteps);

    const changeColorAndSize = (path: SVGPathElement, color: string, d: string) => {
        path.setAttribute('stroke', color);
        path.setAttribute('d', d);
    };

    const resetAllPaths = () => {
        if (!svgGroupRef.current) return;

        const _paths = Array.from(svgGroupRef.current.querySelectorAll('path'));
        _paths.forEach((path, index) => {
            changeColorAndSize(path, 'rgb(255, 255, 255)', shortPaths[index]);
        });
    }

    // const stepThrough = () =>{
    //     if (step === null || step === 4) {
    //         setStep(0)
    //         return
    //     }
    //     setStep(step + 1)
    // }
    //
    // const resetCurrentPath = () => {
    //     if (!svgGroupRef.current || step === null) return;
    //
    //     const _paths = Array.from(svgGroupRef.current.querySelectorAll('path'));
    //     const startPathIndex = step * pathsPerStep;
    //     const endPathIndex = Math.min(startPathIndex + pathsPerStep, totalPaths);
    //
    //     let pathIndex = startPathIndex;
    //
    //     const updatePathWithDelay = () => {
    //         if (pathIndex < endPathIndex) {
    //             const color = 'rgb(255, 255, 255)';
    //             changeColorAndSize(_paths[pathIndex], color, shortPaths[pathIndex]);
    //             pathIndex++;
    //             setTimeout(updatePathWithDelay, 50);
    //         }
    //     };
    //
    //     updatePathWithDelay();
    // }

    useEffect(() => {
        const updatePaths = () => {
            if (!svgGroupRef.current || step === null) return;

            const _paths = Array.from(svgGroupRef.current.querySelectorAll('path'));
            const startPathIndex = step * pathsPerStep;
            const endPathIndex = Math.min(startPathIndex + pathsPerStep, totalPaths);

            let pathIndex = startPathIndex;

            const updatePathWithDelay = () => {
                if (pathIndex < endPathIndex) {
                    const color = '#25C26E';
                    changeColorAndSize(_paths[pathIndex], color, paths[pathIndex]);
                    pathIndex++;
                    setTimeout(updatePathWithDelay, 50);
                }
            };

            updatePathWithDelay();
        };

        updatePaths();
    }, [step]);


    // const handleFailPrevSteps = () => {
    //     if (step === null) return;

    //     const currentStep = step;
    //     const prevSteps = pathsPerStep * (currentStep + 1);

    //     for (let i = prevSteps; i > 0; i--) {
    //         const path = svgGroupRef.current?.querySelector(`path:nth-child(${i})`) as SVGPathElement;
    //         changeColorAndSize(path, '#e83a3a', paths[i - 1]);
    //     }
    // };


    return (
        <section style={{display: "flex", flexDirection: "column", justifyContent: "center"}}>
            <div className="video-area">
                {/* <div className="overlay-wrapper fad-in">
                    <div className="block1"></div>
                    <div className="circle"></div>
                    <div className="block2"></div>
                    <div className="patch1"></div>
                    <div className="patch2"></div>
                </div> */}
                <div className={`animated-svg  ${animate === 'rotate' ? "animate-rotate": ""} ${animate === 'pulse1'? "animate-pulse1": ''} ${animate === 'pulse2'? "animate-pulse2": ''}`}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="250" height="350" viewBox="0 0 260 348" fill="none">
                        <g clipPath="url(#clip0_16139_6120)">
                            <g className="refs" ref={svgGroupRef}>
                                {shortPaths.map((path, index) => (
                                    <path key={index} d={path} stroke="#ffffff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                ))}
                            </g>
                        </g>
                        <defs xmlns="http://www.w3.org/2000/svg">
                            <clipPath id="clip0_16139_6120">
                                <rect width="259.939" height="347.313" fill="white" transform="translate(0.03125 0.5)"/>
                            </clipPath>
                        </defs>
                    </svg>
                </div>
                <div className="video-container">
                    <div className="video">
                        <VideoCapture
                            resetPaths={resetAllPaths}
                            proceed={(step: number) =>setStep(step)}
                            currentStep = {step as number}
                            setAnimate = {(arg: string) => setAnimate(arg)}
                            modelsLoaded={modelsLoaded}
                            setModelsLoaded={(arg: boolean) => setModelsLoaded(arg)}

                        />
                    </div>
                </div>
                {
                    modelsLoaded && (
                        <div className="message-board-wrapper">
                            <div className={`${error? "error-board" : "message-board"}`}>
                                <HeaderContent title={` ☺️  ${error || message}`} />
                            </div>
                            <GetHelpComponent
                                text="Seeing a blank screen?"
                                action="Get Help"
                                routePage={routenames.fixABlankScreen}
                                color={"#C49344"}
                            />
                        </div>
                    )
                }
            </div>
        </section>

    )
}
