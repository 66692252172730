import './fileUpload.css';
import useIcon from "../../hooks/useIcon.ts";
import {ChangeEvent, useEffect, useRef, useState} from "react";
import {useDispatch} from "react-redux";
import {
    convertFileSizeToKBFromBytes, convertFileToBase64,
    truncateFileName
} from "../../utils/helpers.ts";
import {setUploadedID} from "../../../features/ng-verification/verificationSlice.ts";
import {useTranslation} from "react-i18next";
export function FileUpload(){
    const {uploadIcon, _fileIcon, errorExclamationIcon, _trashIcon} = useIcon();
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [uploadedFile, setUploadedFile] = useState<File | null>(null);
    const [fileUploaded, setFileUploaded] = useState<boolean>(false);
    const [progress, setProgress] = useState(0);
    const acceptedFileTypes = ['image/jpeg', 'image/png', 'image/jpg'];
    const [fileError, setFileError] = useState<string | null>(null);
    const dispatch = useDispatch();
    const {t} = useTranslation();

    const  handleFileUpload = async (event:ChangeEvent<HTMLInputElement>) => {
        const maxFileSize = 10 * 1024 * 1024;
        const file = event.target.files && event.target.files[0];
        if(file!.size > maxFileSize){
            setFileError(t('fileUploadComponent.errorText'));
            return;
        }

        setUploadedFile(file);
        setFileUploaded(true);

        if (file) {
            // convert file to base64
            const base64 = await convertFileToBase64(file);
            dispatch(setUploadedID(base64));
        }
    }

    const handleClick = () => {
        fileInputRef.current?.click();
        setFileError(null);
    }
    function clearFileInput(){
        setUploadedFile(null);
        setFileUploaded(false);
        if(fileInputRef.current) fileInputRef.current.value = '';
    }

    /*
    * This function is merely a demo to simulate file progress upload, when API is ready, this function will be removed
    */
    useEffect(() => {
        const progressBarInterval: NodeJS.Timeout = setInterval(() => {
            if (progress < 100) {
                setProgress(progress + 5);
            } else {
                clearInterval(progressBarInterval);
            }
        }, 1000);

        return () => {
            clearInterval(progressBarInterval);
        };
    }, [progress]);

    useEffect(() => {
        dispatch(setUploadedID(null));
    }, []);

    return (
        <>
            {
                fileUploaded
                    ? (
                        <>
                            <section className={`uploaded-component-wrapper ${fileError && 'has-error'}`}>
                                <figure className="file-icon-wrapper">
                                    <img src={_fileIcon} alt="File Icon"/>
                                </figure>
                                <div className="component-info">
                                    <div className="component-info-header">
                                        <p className="file-name">{truncateFileName(uploadedFile!.name)}</p>
                                        <figure className="trash-icon" onClick={clearFileInput}>
                                            <img src={_trashIcon} alt="Trash Icon"/>
                                        </figure>
                                    </div>
                                    <p className="file-size">{convertFileSizeToKBFromBytes(uploadedFile!.size)} KB</p>
                                    {/*<div className="component-info-footer">*/}
                                    {/*    <div className="progress-bar-container">*/}
                                    {/*        <div style={{ width: `${progress}%` }} className="progress-bar"></div>*/}
                                    {/*    </div>*/}
                                    {/*    <span>{progress}%</span>*/}
                                    {/*</div>*/}
                                </div>
                            </section>
                        </>
                    )
                    : (
                        <>
                            <section className="upload-component-input-wrapper">
                                <input
                                    ref={fileInputRef}
                                    type="file"
                                    onChange={handleFileUpload}
                                    className="input-file-ref"
                                    accept={acceptedFileTypes.join(', ')}
                                />

                                <div className={`upload-component-wrapper ${fileError && 'has-error'}`}>
                                    <figure className="upload-icon-container">
                                        <img src={uploadIcon} alt="" />
                                    </figure>
                                    <p className="main-text">
                                        <span onClick={handleClick} className="upload-link">{t('fileUploadComponent.uploadInstructionInit')}</span> {t('fileUploadComponent.uploadInstructionFinal')}
                                    </p>
                                    <p className="supporting-text">{t('fileUploadComponent.supportingText')}</p>
                                </div>
                            </section>
                        </>
                    )
            }
            {
                fileError && (
                    <div style={{height: '12.67px'}} className="error-message-container">
                        <figure className="error-img-box img-box">
                            <img src={errorExclamationIcon} alt="error icon"/>
                        </figure>
                        <p className="error-text">{fileError}</p>
                    </div>
                )
            }
        </>
    );
}
