import {LoadingState} from "../../core/utils/helpers.ts";
import {createSlice} from "@reduxjs/toolkit";
import {
    checkAllowedIDsThunk,
    createVerificationIdentifierThunk, pollSelfieVerificationStatusThunk, sendCustomerIDThunk,
    sendCustomerRecordingThunk, sendFeedbackThunk,
    validateIdentifierThunk
} from "./verificationThunks.ts";
import {IVerificationState} from "../../core/types";
import {persistAllowedIDs, persistVerificationIdentifier} from "./persistenceService.ts";

const initialState : IVerificationState = {
    country: null,
    documentType: null,
    documentCaptureMode: null,
    uploadedID: null,
    capturedID: null,
    metadata: null,
    verificationRequirements: [],
    currentStep: 0,
    vid: null,
    fname: '',
    lname: '',
    id_number: '',
    timestamp: '',
    allowedIDs: null,


    // Loading States and Error States
    createVerificationLoadingState: LoadingState.base,
    createVerificationError: null,

    validateIdentifierLoadingState: LoadingState.base,
    validateIdentifierError: null,

    sendCustomerRecordingLoadingState: LoadingState.base,
    sendCustomerRecordingError: null,

    pollSelfieVerificationLoadingState: LoadingState.base,
    pollSelfieVerificationSuccessMessage: {},
    pollSelfieVerificationError: null,

    checkAllowedIDsLoadingState: LoadingState.base,
    checkAllowedIDsError: null,

    uploadIDLoadingState: LoadingState.base,
    uploadIDError: null,

    sendFeedbackLoadingState: LoadingState.base,
    sendFeedbackError: null,

}


const verificationSlice = createSlice({
    name: 'verification',
    initialState,
    reducers: {
        setCountry: (state, {payload}) => {
            state.country = payload;
        },
        setMetadata: (state, {payload}) => {
            state.metadata = payload;
        },
        setUploadedID: (state, {payload}) => {
            state.uploadedID = payload;
        },
        setCapturedID: (state, {payload}) => {
            state.capturedID = payload;
        },
        setDocumentType: (state, {payload}) => {
            state.documentType = payload;
        },
        setDocumentCaptureMode: (state, {payload}) => {
            state.documentCaptureMode = payload;
        },
        setVerificationRequirements: (state, {payload}) => {
            state.verificationRequirements = payload;
        },
        setCurrentStep: (state, {payload}) => {
            state.currentStep += payload;
        },
        setFName: (state, {payload}) => {
            state.fname = payload;
        },
        setLName: (state, {payload}) => {
            state.lname = payload;
        },
        setIDNumber: (state, {payload}) => {
            state.id_number = payload;
        },
        setTimestamp: (state, { payload }) => {
            state.timestamp = payload
        },
        setVid: (state, { payload }) => {
            state.vid = payload
        }
    },
    extraReducers: builder => {
        builder.addCase(createVerificationIdentifierThunk.pending, (state) => {
            state.createVerificationLoadingState = LoadingState.loading;
        });
        builder.addCase(createVerificationIdentifierThunk.fulfilled, (state, {payload}) => {
            state.createVerificationLoadingState = LoadingState.successful;
            state.vid = payload;
            persistVerificationIdentifier(payload);
        });
        builder.addCase(createVerificationIdentifierThunk.rejected, (state, {payload}) => {
            state.createVerificationLoadingState = LoadingState.error;
            state.createVerificationError = payload as string;
        });

        builder.addCase(validateIdentifierThunk.pending, (state) => {
            state.validateIdentifierLoadingState = LoadingState.loading;
        });
        builder.addCase(validateIdentifierThunk.fulfilled, (state,) => {
            state.validateIdentifierLoadingState = LoadingState.successful;
        });
        builder.addCase(validateIdentifierThunk.rejected, (state, {payload}) => {
            state.validateIdentifierLoadingState = LoadingState.error;
            state.validateIdentifierError = payload as string;
        });


        builder.addCase(sendCustomerRecordingThunk.pending, (state) => {
            state.sendCustomerRecordingLoadingState = LoadingState.loading;
        });
        builder.addCase(sendCustomerRecordingThunk.fulfilled, (state) => {
            state.sendCustomerRecordingLoadingState = LoadingState.successful;
        });
        builder.addCase(sendCustomerRecordingThunk.rejected, (state, {payload}) => {
            state.sendCustomerRecordingLoadingState = LoadingState.error;
            state.sendCustomerRecordingError = payload as string;
        });


        builder.addCase(pollSelfieVerificationStatusThunk.pending, (state) => {
            state.pollSelfieVerificationLoadingState = LoadingState.loading;
        });
        builder.addCase(pollSelfieVerificationStatusThunk.fulfilled, (state, {payload}) => {
            state.pollSelfieVerificationLoadingState = LoadingState.successful;
            if (payload.status === "verified"){
                state.pollSelfieVerificationSuccessMessage = {
                    header: "Identity verification successful",
                    subTitle: "Congratulations! You've successfully completed this verification process. Thank you for your patience."
                }
            } else if (payload.status === 'awaiting_approval'){
                state.pollSelfieVerificationSuccessMessage = {
                    header: "Documents submitted successfully",
                    subTitle: "Your verification has been submitted and is being reviewed by someone from our team, we’ll let you know when its completed."
                }
            }
        });
        builder.addCase(pollSelfieVerificationStatusThunk.rejected, (state,{ payload }) => {
            state.pollSelfieVerificationLoadingState = LoadingState.error;
            state.pollSelfieVerificationError = payload as string;
        });


        builder.addCase(checkAllowedIDsThunk.pending, (state) => {
            state.checkAllowedIDsLoadingState = LoadingState.loading;
        });
        builder.addCase(checkAllowedIDsThunk.fulfilled, (state, {payload}) => {
            state.checkAllowedIDsLoadingState = LoadingState.successful;
            state.allowedIDs = payload;
            persistAllowedIDs(payload);
        });
        builder.addCase(checkAllowedIDsThunk.rejected, (state) => {
            state.checkAllowedIDsLoadingState = LoadingState.error;
        });


        builder.addCase(sendCustomerIDThunk.pending, (state) => {
            state.uploadIDLoadingState = LoadingState.loading;
        });
        builder.addCase(sendCustomerIDThunk.fulfilled, (state) => {
            state.uploadIDLoadingState = LoadingState.successful;
        });
        builder.addCase(sendCustomerIDThunk.rejected, (state) => {
            state.uploadIDLoadingState = LoadingState.error;
        });


        builder.addCase(sendFeedbackThunk.pending, (state) => {
            state.sendFeedbackLoadingState = LoadingState.loading;
        });
        builder.addCase(sendFeedbackThunk.fulfilled, (state,) => {
            state.sendFeedbackLoadingState = LoadingState.successful;
        });
        builder.addCase(sendFeedbackThunk.rejected, (state, {payload}) => {
            state.sendFeedbackLoadingState = LoadingState.error;
            state.sendFeedbackError = payload as string;
        });

    }
})

export default verificationSlice.reducer;
export const {
    setCountry,
    setMetadata,
    setUploadedID,
    setCapturedID,
    setDocumentType,
    setDocumentCaptureMode,
    setVerificationRequirements,
    setCurrentStep,
    setFName,
    setIDNumber,
    setLName,
    setTimestamp,
    setVid,
} = verificationSlice.actions;
