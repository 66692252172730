/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { createContext, useContext, useState, ReactNode } from 'react';

type VideoTestContextType = {
  message: string;
  error: string | null;
  step: number | null;
  setMessage: React.Dispatch<React.SetStateAction<string>>;
  setError: React.Dispatch<React.SetStateAction<string | null>>;
  setStep: React.Dispatch<React.SetStateAction<number | null>>;
};

const VideoTestContext = createContext<VideoTestContextType | undefined>(undefined);

export function useVideoTestContext() {
  const context = useContext(VideoTestContext);
  if (!context) {
    throw new Error('useVideoTestContext must be used within a VideoTestContextProvider');
  }
  return context;
}

export function VideoTestContextProvider({ children }: { children: ReactNode }) {
    const [message, setMessage] = useState<string>("Position your face within the frame");
    const [error, setError]= useState<string | null>(null)
    const [step, setStep] = useState<number|null>(null);

  return (
    <VideoTestContext.Provider value={{ message, error, step, setMessage: (msg) => setMessage(msg), setError, setStep }}>
      {children}
    </VideoTestContext.Provider>
  );
}
