/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useRef, useState } from 'react';
import HeaderContent from '../headerContent/HeaderContent.tsx';
import shutterSound from '../../../../core/assets/sounds/shutter.mp3';
import { useNavigate } from 'react-router-dom';
import RouteNames from '../../../../core/routes/routenames.ts';
import { useDispatch } from 'react-redux';
import { setCapturedID } from '../../verificationSlice.ts';
import useCheckDeviceType from '../../../../core/hooks/useCheckDeviceType.ts';
import { PiCameraRotateBold } from 'react-icons/pi';
import ShutterImage from '../../../../core/assets/images/shutter.svg?react';

interface messageType {
  miniPopUp: string;
  header: string;
}

export interface maskCoodType {
  bottom: number;
  height: number;
  left: number;
  right: number;
  top: number;
  width: number;
  x: number;
  y: number;
}
export default function CaptureIDComponent(): JSX.Element {
  const videoRef = useRef<HTMLVideoElement>(null);
  const photoRef = useRef<HTMLCanvasElement>(null);
  const maskOverlayRef = useRef<HTMLDivElement | null>(null);

  const router = useNavigate();
  const dispatch = useDispatch();
  const { isDesktop, orientation } = useCheckDeviceType();
  const shutter: HTMLAudioElement = new Audio(shutterSound);
  const [message, setMessage] = useState<messageType>({
    miniPopUp: '☺️ Hold Still',
    header: 'Capture the front of your ID',
  });

  let currentStream: { getTracks: () => any[] } | null = null;
  let isFrontCamera = false;

  const startVideo = () => {
    const facingMode = isFrontCamera ? 'user' : 'environment';
    navigator.mediaDevices
      .getUserMedia({ video: { facingMode } })
      .then((stream) => {
        currentStream = stream;
        if (videoRef.current) {
          const video: HTMLVideoElement = videoRef.current;
          video.srcObject = stream;
          video.play();
        }
      })
      .catch((_err) => {
        // console.log(err);
      });
  };

  const stopVideo = () => {
    if (videoRef.current) {
      const stream = videoRef.current.srcObject as MediaStream;
      if (stream) {
        const tracks = stream.getTracks();
        tracks.forEach((track) => {
          track.stop();
        });
      }
      videoRef.current.srcObject = null;
    }
  };

  const getMaskOverlayPosition = (): maskCoodType | void => {
    const maskOverlayElement = maskOverlayRef.current;
    if (maskOverlayElement) {
      const rect = maskOverlayElement.getBoundingClientRect();
      return rect;
    }
  };

  const takePhoto = () => {
    const video = videoRef.current;
    const photo = photoRef.current;

    // Play the shutter sound
    shutter.play();
    setMessage((prevMessage) => ({
      ...prevMessage,
      miniPopUp: 'Captured successfully',
    }));

    const ctx = photo!.getContext('2d');
    const maskPosition = getMaskOverlayPosition() || {
      top: 0,
      left: 0,
      width: 0,
      height: 0,
    };

    const width = maskPosition.width;
    const height = maskPosition.height;
    const centerX = photo!.width / 2 - 20;
    const centerY = photo!.height / 3 - 10;
    photo!.width = width;
    photo!.height = height;

    if (video) {
      ctx!.clearRect(0, 0, photo!.width, photo!.height);
      if (orientation === 'Portrait') {
        ctx!.drawImage(
          video,
          centerX - 50,
          centerY + 60,
          width,
          height,
          0,
          0,
          width,
          height,
        );
      } else {
        ctx!.drawImage(
          video,
          centerX,
          centerY,
          width,
          height,
          0,
          0,
          width,
          height,
        );
      }
    }

    const photoInBase64 = photo!.toDataURL('image/png');
    dispatch(setCapturedID(photoInBase64));
    stopVideo();
    router(RouteNames.captureIDSuccess);
  };

  const toggleCamera = () => {
    if (currentStream) {
      currentStream.getTracks().forEach((track) => track.stop());
    }
    isFrontCamera = !isFrontCamera;
    startVideo();
  };

  useEffect(() => {
    startVideo();
  }, [videoRef]);

  useEffect(() => {
    dispatch(setCapturedID(null));
    setMessage((prevMessage) => ({
      ...prevMessage,
      miniPopUp: '☺️ Hold Still',
      header: 'Capture the front of your ID',
    }));
  }, []);

  return (
    <div className="page-wrapper">
      <section className="photo-wrapper">
        {/*<div className="overlay-wrapper">*/}
        {/*    <div className="block1"></div>*/}
        {/*    <div className="box"></div>*/}
        {/*    <div className="patch2"></div>*/}
        {/*    <div className="patch3"></div>*/}
        {/*</div>*/}
        <article className="camera">
          <video
            height="422"
            crossOrigin="anonymous"
            ref={videoRef}
            muted
            playsInline={true}
            autoPlay
            controls={false}
          ></video>
        </article>
        <article className="result">
          <canvas height="422" ref={photoRef}></canvas>
          <div className="mask-overlay" ref={maskOverlayRef}></div>
        </article>
      </section>

      <section style={{ marginTop: '17rem' }}>
        <div className="mini-popup-container">
          <span>{message.miniPopUp}</span>
        </div>
        <div className="header-content-container">
          <HeaderContent
            title={message.header}
            subTitle="Make sure all details are visible and legible. Thank you!"
          />
        </div>
        <footer>
          <section className="button-container">
            <figure className="shutter-button-wrapper" onClick={takePhoto}>
              <ShutterImage />
            </figure>
          </section>
          <section className="toggle-button-container">
            {!isDesktop && (
              <figure className="icon-wrapper" onClick={toggleCamera}>
                <span>
                  <PiCameraRotateBold size={20} />
                </span>
              </figure>
            )}
          </section>
        </footer>
      </section>
    </div>
  );
}
