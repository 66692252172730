/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-inner-declarations */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useMemo, useRef, useState } from 'react';
import * as faceapi from 'face-api.js';
import { useNavigate } from 'react-router-dom';
import { useCaptureContext } from '../../../../core/context/CaptureContextProvider.tsx';
import { useReactMediaRecorder } from 'react-media-recorder';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { setTimestamp } from '../../verificationSlice.ts';
import { useVideoTestContext } from '../../../../core/context/VideoTestContextProvider.tsx';
import LoaderComponent from '../loaderComponent/LoaderComponent.tsx';
import RouteNames from '../../../../core/routes/routenames.ts';
import ModalLayout from '../../../../core/uikit/modalLayout/ModalLayout.tsx';
import ButtonReuse from '../../../../core/uikit/button/Button.tsx';
import HeaderContent from '../headerContent/HeaderContent.tsx';
import GetHelpComponent from '../getHelpComponent/GetHelpComponent.tsx';
import TakeSelfieImg from '../../../../core/assets/images/take-selfie.svg?react';
import { showToast } from '../../toastSlice.ts';
import ToastV2 from '../../../../core/uikit/toast/toast.tsx';

interface propType {
  proceed: (step: number) => void;
  currentStep: number;
  resetPaths: () => void;
  setAnimate: (arg: string) => void;
  modelsLoaded: boolean;
  setModelsLoaded: (arg: boolean) => void;
}

enum FaceDirection {
  LEFT = 'left',
  RIGHT = 'right',
  NULL = 'null',
}

const VideoCapture = ({
  proceed,
  currentStep,
  setAnimate,
  resetPaths,
  modelsLoaded,
  setModelsLoaded,
}: propType) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const intervalIdRef = useRef<NodeJS.Timeout | null>(null);

  const router = useNavigate();
  const [navigate, setNavigate] = useState<boolean>(false);
  const [detectionData, setDetectionData] = useState<any>({});
  const [facingDirection, setFacingDirection] = useState<string | null>(
    FaceDirection.NULL,
  );
  const { setCustomerRecording } = useCaptureContext();
  const dispatch = useDispatch();
  const { setMessage, setError } = useVideoTestContext();
  const [startTime, setStartTime] = useState<number>(0);
  const [modalVisible, setModalVisible] = useState(false);

  // const getSupportedMimeType = () => {
  //   if (MediaRecorder.isTypeSupported('video/webm; codecs=vp8')) {
  //     return { mimeType: 'video/webm; codecs=vp8' };
  //   } else if (MediaRecorder.isTypeSupported('video/webm')) {
  //     return { mimeType: 'video/webm' };
  //   } else if (MediaRecorder.isTypeSupported('video/mp4')) {
  //     return { mimeType: 'video/mp4' };
  //   } else {
  //     console.error('no suitable mimetype found for this device');
  //   }
  // };

  const { status, startRecording, stopRecording, mediaBlobUrl } =
    useReactMediaRecorder({
      video: true,
      audio: false, // You can set this to false if you don't need audio recording
    });

  useEffect(() => {
    // LOAD MODELS FROM FACE API
    const loadModels = () => {
      Promise.all([
        faceapi.nets.tinyFaceDetector.loadFromUri('/models'),
        faceapi.nets.faceLandmark68Net.loadFromUri('/models'),
        faceapi.nets.faceExpressionNet.loadFromUri('/models'),
      ]).then(() => {
        setModelsLoaded(true);
      });
    };

    loadModels();
  }, []);

  // ACCUMULATE CHANGES IN _x
  function calculateAccumulatedDifference(arr: any) {
    if (!Array.isArray(arr) || arr.length === 0) {
      return 0;
    }

    const differences = arr.map((item, index) => {
      if (index === 0) {
        return 0;
      } else {
        return item._x - arr[index - 1]._x;
      }
    });

    return differences.reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      0,
    );
  }

  const startVideo = () => {
    navigator.mediaDevices
      .getUserMedia({
        video: { width: { ideal: 1280 }, height: { ideal: 720 } },
      })
      .then((currentStream) => {
        if (videoRef.current) {
          videoRef.current.srcObject = currentStream;
          videoRef.current.style.transform = 'scaleX(-1)';
          memoizedPerformFaceDetection(); // START FACE DETECTION
        }
      })
      .catch((err) => {
        if (err.name === 'OverconstrainedError') {
          console.warn('720p not supported, falling back to 480p');
          navigator.mediaDevices
            .getUserMedia({
              video: { width: { ideal: 854 }, height: { ideal: 480 } },
            })
            .then((currentStream) => {
              if (videoRef.current) {
                videoRef.current.srcObject = currentStream;
                videoRef.current.style.transform = 'scaleX(-1)';
                memoizedPerformFaceDetection(); // START FACE DETECTION
              }
            });
        } else {
          console.error('Error accessing camera:', err);
        }
      });
  };

  const stopVideo = () => {
    if (videoRef.current) {
      const stream = videoRef.current.srcObject as MediaStream;
      if (stream) {
        const tracks = stream.getTracks();
        tracks.forEach((track) => {
          track.stop();
        });
      }
      videoRef.current.srcObject = null;
      videoRef.current = null;
    }
  };

  // FACE DETECTION BLACK_BOX: RESULT SAVED AS 'DetectionData'
  const faceMyDetect = () => {
    let count = 0;
    const scaleFactor = 0.5;

    const performFaceDetection = async () => {
      if (videoRef.current && canvasRef.current) {
        // console.log(count, "Run")
        count += 1;
        canvasRef.current.style.transform = 'scaleX(-1)';

        const detections = await faceapi
          .detectAllFaces(
            videoRef.current,
            new faceapi.TinyFaceDetectorOptions({ inputSize: 128 }),
          )
          .withFaceLandmarks()
          .withFaceExpressions();

        // Clear the canvas
        const context = canvasRef.current.getContext('2d');
        context!.clearRect(
          0,
          0,
          canvasRef.current.width,
          canvasRef.current.height,
        );

        const displaySize = {
          width: videoRef.current.clientWidth * scaleFactor,
          height: videoRef.current.clientHeight * scaleFactor,
        };

        faceapi.matchDimensions(canvasRef.current, displaySize);

        if (detections.length === 1) {
          // WHEN ONE FACE IS DETECTED
          setError(null);
          setDetectionData(detections[0]);
          const landmarks = detections[0].landmarks;

          const leftEye = landmarks.getLeftEyeBrow();
          const rightEye = landmarks.getRightEyeBrow();

          const accummulatedGapLeftEyeX =
            calculateAccumulatedDifference(leftEye);
          const accummulatedGapRightEyeX =
            calculateAccumulatedDifference(rightEye);

          if (accummulatedGapLeftEyeX - accummulatedGapRightEyeX > 15) {
            setFacingDirection(FaceDirection.LEFT);
          } else if (accummulatedGapRightEyeX - accummulatedGapLeftEyeX > 15) {
            setFacingDirection(FaceDirection.RIGHT);
          }
        } else if (detections.length > 1) {
          setFacingDirection(null);
          setError("Make sure there's just one person in the camera");
        } else {
          setFacingDirection(null);
        }
        // console.log( count, 'Run >>>>', detections)
        // const resizedDetections = faceapi.resizeResults(detections, displaySize);
        // faceapi.draw.drawDetections(canvasRef.current, resizedDetections);
        // faceapi.draw.drawFaceLandmarks(canvasRef.current, resizedDetections);
        // faceapi.draw.drawFaceExpressions(canvasRef.current, resizedDetections);
      }
    };

    intervalIdRef.current = setInterval(performFaceDetection, 1800);
  };

  const checkCameraPermissions = async (auto = true) => {
    try {
      // Directly request camera access
      await navigator.mediaDevices.getUserMedia({
        video: { width: { ideal: 1280 }, height: { ideal: 720 } },
      });
      startVideo();
      setError('Move closer and hold still so we can detect your face');
      // setError(null)
      setModalVisible(false);
    } catch (error: any) {
      !auto && dispatch(showToast({
        type: 'error',
        heading: 'Camera Access Still Unavailable!',
        description: 'Please double-check your browser settings to ensure camera permissions are enabled.',
      }));

      if (error.name === 'NotAllowedError') {
        setError(
          'Camera access denied. Please enable camera permissions in your browser settings.',
        );
        setModalVisible(true);
      } else {
        setError('Error accessing the camera.');
        setModalVisible(true);
      }
    }
  };

  const memoizedPerformFaceDetection = useMemo(
    () => faceMyDetect,
    [videoRef?.current],
  );

  useEffect(() => {
    // startVideo();
    checkCameraPermissions();
  }, [memoizedPerformFaceDetection]);

  // const cropVideoFrame = useCallback(() => {
  //   const video = videoRef.current;
  //   const canvas = canvasRef.current;
  //   if (video && canvas) {
  //     const ctx = canvas.getContext('2d');

  //     if (ctx) {
  //       const onPlaying = () => {
  //         const options = getSupportedMimeType();

  //         // Set up MediaRecorder to capture canvas stream
  //         const stream = canvas.captureStream(30); // 30 fps
  //         const mediaRecorder = new MediaRecorder(stream, options);
  //         const chunks: any = [];

  //         mediaRecorder.ondataavailable = (event) => {
  //           if (event.data.size > 0) {
  //             chunks.push(event.data);
  //           }
  //         };

  //         mediaRecorder.onstop = () => {
  //           const blob = new Blob(chunks, { type: 'video/mp4' });
  //           const url = URL.createObjectURL(blob);
  //           setCustomerRecording(url);
  //           setNavigate(true);
  //         };

  //         if (status === 'acquiring_media') {
  //           console.log(
  //             'VIDOE DIM -OFFSET',
  //             `(${video.offsetHeight},${video.offsetWidth})`,
  //           );
  //           console.log(
  //             'VIDOE DIM',
  //             `(${video.videoHeight},${video.videoWidth})`,
  //           );
  //           mediaRecorder.start();
  //         }

  //         const videoWidth = video.videoWidth;
  //         const videoHeight = video.videoHeight;

  //         // Calculate the center of the video
  //         const centerX = videoWidth / 2;
  //         const centerY = videoHeight / 2;

  //         // Define the crop area dimensions
  //         const cropWidth = video.offsetWidth;
  //         const cropHeight = video.offsetHeight;

  //         // Calculate the top-left corner of the crop area
  //         const cropTopX = Math.max(0, centerX - cropWidth / 2);
  //         const cropTopY = Math.max(0, centerY - cropHeight / 2);

  //         // Define the target dimensions on the canvas
  //         const targetWidth = 220;
  //         const targetHeight = targetWidth * (5 / 3);

  //         // Draw the video frame on the canvas periodically
  //         const interval = setInterval(() => {
  //           ctx.drawImage(
  //             video,
  //             cropTopX,
  //             cropTopY,
  //             cropWidth,
  //             cropHeight,
  //             0,
  //             0,
  //             targetWidth,
  //             targetHeight,
  //           );
  //         }, 1000 / 30);

  //         const checkRecordingStatus = setInterval(() => {
  //           clearInterval(interval);
  //           mediaRecorder.stop();
  //           clearInterval(checkRecordingStatus);
  //         }, 9000); // Records for 10secs
  //       };

  //       video.addEventListener('playing', onPlaying, { once: true });

  //       // Force the video to play if it hasn't already
  //       if (video.readyState >= 2) {
  //         // console.log("Video is already playing, manually triggering");
  //         onPlaying();
  //       } else {
  //         // console.log("Waiting for video to start playing");
  //       }
  //     }
  //   }
  // }, [status, router]);

  // TEST ARE RECALIBRATION DEBUGGER CODE ++++++ Don't delete
  // useEffect(() => {
  //     if (!modelsLoaded) return;

  //     const canvas = canvasRef.current;
  //     const ctx = canvas!.getContext('2d');

  //     function drawNode(x: number, y: number, radius: number) {
  //         ctx!.beginPath();
  //         ctx!.arc(x, y, radius, 0, 2 * Math.PI);

  //         ctx!.strokeStyle = 'black'; // Node border color
  //         ctx!.lineWidth = 2;
  //         ctx!.stroke();
  //         ctx!.closePath();
  //     }

  //      // Draw a square with the specified line thickness
  //     const width = 280;
  //     const height = 350;
  //     const offset = 120;
  //     const centerX = canvas!.width / 2 - width / 2;
  //     const centerY = (canvas!.height / 2 - height / 2) - offset;

  //     // Set the line thickness
  //     ctx!.lineWidth = 3;
  //     if (detectionData.alignedRect){
  //         if (detectionData?.alignedRect._box.top >= centerY && detectionData?.alignedRect._box.bottom <= centerY + (height / 2) + 80 && detectionData?.alignedRect._box.left <= centerX - 50 && detectionData?.alignedRect._box.left >= centerX - (width/2) + 20) {
  //             ctx!.strokeStyle = "green";
  //         } else {
  //             ctx!.strokeStyle = "red";
  //         }
  //     }

  //     ctx!.fillStyle = 'blue'; // Node fill color
  //     ctx!.fill();
  //     ctx!.arc(50, 50, 20, 0, 2 * Math.PI);
  //     ctx!.strokeRect(centerX, centerY, width, height);

  //     if (detectionData?.alignedRect) {
  //         console.log(detectionData?.alignedRect._box.bottom, "======> bottom", centerY + (height / 2) + 80 ) // bottom <= centerY + (boxheight/2) + offest (70 + 10)
  //         // console.log(detectionData?.alignedRect._box.top, "======> top", centerY  ) // top >= centerY
  //         // console.log(detectionData?.alignedRect._box.left, "======> lefter", centerX - 50 ) // left <= centerX
  //         // console.log(detectionData?.alignedRect._box.left, "======> right", centerX - (width/2) + 20) // left + width >= centerX + offset(70 +10)
  //     }

  // }, [detectionData]);

  /*
    CONFIRMED CLASSIFIERS
    ==================================================================
     1. Detect a face
     2. Watch for a smile
     3. Watch for a calm face
     4. Watch for a human head facing left
     5. Watch for a human head facing right
     ==================================================================
      */
  useEffect(() => {
    if (!modelsLoaded) {
      setMessage('Loading the models');
      return;
    }

    // const canvas = canvasRef.current;

    // const width = 280;
    // const height = 350;
    // const offset = 120;
    // const centerX = canvas!.width / 2 - width / 2;
    // const centerY = (canvas!.height / 2 - height / 2) - offset;

    // CHECKS IF A FACE WAS DETECTED AND WITHIN THE GREEN AREA
    // const _testCondition = detectionData.detection && detectionData?.alignedRect._box.top >= centerY && detectionData?.alignedRect._box.bottom <= centerY + (height / 2) + 80 && detectionData?.alignedRect._box.left <= centerX - 50 && detectionData?.alignedRect._box.left >= centerX - (width/2) + 20

    if (detectionData.detection) {
      if (currentStep === null) {
        resetPaths();
      }
      if (currentStep === null && detectionData.detection._score >= 0.5) {
        setAnimate('pulse2');
        const now = Date.now();
        setStartTime(now);
        startRecording();
        setTimeout(() => {
          proceed(0);
          setMessage('Good job! Now, give us a big smile.');
        }, 500);
      }
      if (currentStep === 0 && detectionData.expressions.happy >= 0.95) {
        setAnimate('pulse1');
        setTimeout(() => {
          proceed(1);
          dispatch(
            setTimestamp(
              moment
                .utc(moment(Date.now()).diff(moment(startTime)))
                .format('HH:mm:ss'),
            ),
          );
          setMessage('Nice smile! You can relax now.');
        }, 500);
      }
      if (currentStep === 1 && detectionData.expressions.happy < 0.95) {
        setAnimate('pulse2');
        setTimeout(() => {
          proceed(2);
          setMessage('Please turn your head slowly to the left.');
        }, 500);
      }
      if (currentStep === 2 && facingDirection === FaceDirection.LEFT) {
        setAnimate('pulse1');
        setTimeout(() => {
          proceed(3);
          setMessage('Now, turn your head slowly to the right.');
        }, 500);
      }
      if (currentStep === 3 && facingDirection === FaceDirection.RIGHT) {
        setAnimate('pulse2');
        proceed(4);
        setMessage('All done! Great job.');
        stopRecording();
        setNavigate(true)
      }
    } else {
      setError('Move closer and hold still so we can detect your face');
    }
  }, [detectionData, currentStep, modelsLoaded]);

  useEffect(() => {
    // cropVideoFrame();
    setCustomerRecording(mediaBlobUrl);
    if (status === 'stopped' && navigate) {
      stopVideo();
      router(RouteNames.videoConfirmation);
    }
  }, [mediaBlobUrl, status, navigate, router]);

  return !modelsLoaded ? (
    <div style={{ marginTop: '40%' }}>
      <LoaderComponent />
    </div>
  ) : (
    <div style={{ width: '100%', height: '100%' }}>
      <video
        crossOrigin="anonymous"
        height="694"
        ref={videoRef}
        muted
        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
        playsInline={true}
        autoPlay
      ></video>

      <canvas ref={canvasRef} height="694" className="appcanvas" />

      <ModalLayout
        width="48.7rem"
        visible={modalVisible}
        onClose={() => setModalVisible(true)}
      >
        <section className="camera-access-wrapper">
          <HeaderContent
            title="Camera Access Needed!"
            subTitle="To continue, please allow camera access in your browser settings."
          />
          <figure className="main-content-img">
            <TakeSelfieImg />
          </figure>
          <div className="main-content">
            <ButtonReuse
              onClick={() => checkCameraPermissions(false)}
              btnStyle="btn-reuse"
              text="I have enabled the camera"
            />

            <GetHelpComponent
              text="Trouble enabling the camera?"
              action="Get Help"
              routePage={RouteNames.fixABlankScreen}
              color={'#737a91'}
            />
          </div>
        </section>
      </ModalLayout>

      <ToastV2 />
    </div>
  );
};

export default VideoCapture;
