import './InputGroup.css';
import { ChangeEvent } from 'react';
import InputErrorMessage from './components/InputGroupMessage.tsx';

interface InputGroupProps {
  type?: string;
  placeholder?: string;
  name?: string;
  value?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  rightIcon?: null | string;
  label?: null | string;
  isError?: boolean;
  errorMessage?: string;
  leftIcon?: null | string;
  containerClass?: string;
}

export function InputGroup({
  type = 'text',
  placeholder = '',
  name = '',
  value = '',
  onChange = () => {},
  rightIcon = null,
  leftIcon = null,
  label = null,
  isError = false,
  errorMessage = 'Error Message',
  containerClass = '',
}: InputGroupProps): JSX.Element {
  return (
    <div className={`rkyc-input-group-wrapper ${containerClass}`}>
      {rightIcon && (
        <figure className="rkyc-input-group-icon-wrapper">
          <img
            className="rkyc-input-group-icon"
            src={rightIcon}
            alt="search-icon"
          />
        </figure>
      )}
      {leftIcon && (
        <figure className="rkyc-input-group-icon-wrapper--left">
          <img
            className="rkyc-input-group-icon"
            src={leftIcon}
            alt="search-icon"
          />
        </figure>
      )}
      {label && <p className="rkyc-input-group-label">{label}</p>}
      <input
        className={`rkyc-input-group ${isError ? 'has-error' : ''}`}
        type={type}
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={onChange}
      />
      {isError && (
        <div className="error-message-container">
          {isError && <InputErrorMessage errorMessage={errorMessage} />}
        </div>
      )}
    </div>
  );
}
