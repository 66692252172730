import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import RouteNames from './routenames.ts';
import Routenames from './routenames.ts';
import NGMainContent from '../containers/NGMainContent.tsx';
import { SelectCountry } from '../../features/ng-verification/pages/select-country/SelectCountry.tsx';
import { EnterYourBVN } from '../../features/ng-verification/pages/enter-your-bvn/EnterYourBVN.tsx';
import UploadID from '../../features/ng-verification/pages/upload-id/UploadID.tsx';
import CaptureFace from '../../features/ng-verification/pages/capture-face/CaptureFace.tsx';
import CaptureSuccess from '../../features/ng-verification/pages/capture-success/CaptureSuccess.tsx';
import { GetVerified } from '../../features/ng-verification/pages/get-verified/GetVerified.tsx';
import { IDIntro } from '../../features/ng-verification/pages/id-intro/IDIntro.tsx';
import { TakeSelfie } from '../../features/ng-verification/pages/take-selfie/TakeSelfie.tsx';
import { CaptureID } from '../../features/ng-verification/pages/capture-id/CaptureID.tsx';
import CaptureIDSuccess from '../../features/ng-verification/pages/capture-id-success/CaptureIDSuccess.tsx';
import VerificationComplete from '../../features/ng-verification/pages/verificationComplete/VerificationComplete.tsx';
import { CaptureContextProvider } from '../context/CaptureContextProvider.tsx';
import DocumentCaptureMode from '../../features/ng-verification/pages/document-capture-mode/DocumentCaptureMode.tsx';
import SelectOfficialDocument from '../../features/ng-verification/pages/select-official-document/SelectOfficialDocument.tsx';
import { BVNIntro } from '../../features/ng-verification/pages/bvn-intro/BVNIntro.tsx';
import { EnterYourDetails } from '../../features/ng-verification/pages/enter-your-details/EnterYourDetails.tsx';
import IDReceived from '../../features/ng-verification/pages/id-received/IDReceived.tsx';
import { IPointsForRoutes } from '../types';
import VerificationFailed from '../../features/ng-verification/pages/verificationFailed/VerificationFailed.tsx';
import FixABlankScreen from '../../features/ng-verification/components/getHelpComponent/FixABlankScreen.tsx';
import DropFeedback from '../../features/ng-verification/components/getHelpComponent/DropFeedback.tsx';
import FeedbackComplete from '../../features/ng-verification/components/getHelpComponent/FeedbackComplete.tsx';
import VideoConfirmation from '../../features/ng-verification/pages/video-confirmation/VideoConfirmation.tsx';

export default function Router() {
  // const {isDesktop} = useCheckDeviceType();
  const router = createBrowserRouter([
    {
      path: RouteNames.home,
      element: <NGMainContent />,
      children: [
        {
          index: true,
          // element: isDesktop ? <ScanQRCode /> : <SelectCountry />,
          element: <SelectCountry />,
        },
        {
          path: RouteNames.getVerified,
          element: <GetVerified />,
        },
        {
          path: RouteNames.IDIntro,
          element: <IDIntro />,
        },
        {
          path: RouteNames.BVNIntro,
          element: <BVNIntro />,
        },
        {
          path: RouteNames.enterBVN,
          element: <EnterYourBVN />,
        },
        {
          path: RouteNames.uploadId,
          element: <UploadID />,
        },
        // {
        //   path: RouteNames.capture,
        //   element: <Capture />,
        // },
        {
          path: RouteNames.captureID,
          element: <CaptureID />,
        },
        {
          path: RouteNames.captureIDSuccess,
          element: <CaptureIDSuccess />,
        },
        {
          path: RouteNames.takeSelfie,
          element: <TakeSelfie />,
        },
        {
          path: RouteNames.captureFace,
          element: <CaptureFace />,
        },
        {
          path: RouteNames.videoConfirmation,
          element: <VideoConfirmation />,
        },
        {
          path: RouteNames.captureSuccess,
          element: <CaptureSuccess />,
        },
        {
          path: RouteNames.verificationComplete,
          element: <VerificationComplete />,
        },
        {
          path: RouteNames.verificationFailed,
          element: <VerificationFailed />,
        },
        {
          path: RouteNames.documentCaptureMode,
          element: <DocumentCaptureMode />,
        },
        {
          path: RouteNames.selectOfficialDocument,
          element: <SelectOfficialDocument />,
        },
        {
          path: RouteNames.enterYourDetails,
          element: <EnterYourDetails />,
        },
        {
          path: RouteNames.idReceived,
          element: <IDReceived />,
        },
        {
          path: RouteNames.fixABlankScreen,
          element: <FixABlankScreen />,
        },
        {
          path: Routenames.dropFeedback,
          element: <DropFeedback />,
        },
        {
          path: Routenames.feedbackComplete,
          element: <FeedbackComplete />,
        },
      ],
    },
    // {
    //   path: eurRouteNames.europe,
    //   element: <EURMainContent />,
    //   children: [
    //     {
    //       index: true,
    //       element: <EnterAddress />,
    //     },
    //     {
    //       path: eurRouteNames.selectADocumentToUpload,
    //       element: <SelectADocumentToUpload />,
    //     },
    //     {
    //       path: eurRouteNames.documentCaptureMode,
    //       element: <DocumentCaptureModeEUR />,
    //     },
    //     {
    //       path: eurRouteNames.uploadId,
    //       element: <UploadIDEUR />,
    //     },
    //     {
    //       path: eurRouteNames.captureID,
    //       element: <CaptureIDEUR />,
    //     },
    //     {
    //       path: eurRouteNames.captureIDSuccess,
    //       element: <CaptureIDSuccessEUR />,
    //     },
    //     {
    //       path: eurRouteNames.languageSelection,
    //       element: <LanguageSelection />,
    //     },
    //     {
    //       path: eurRouteNames.verificationProcessing,
    //       element: <VerificationProcessing />,
    //     },
    //     {
    //       path: eurRouteNames.verificationComplete,
    //       element: <VerificationCompleteEUR />,
    //     },
    //   ],
    // },
  ]);

  return (
    <CaptureContextProvider>
      <RouterProvider router={router} />
    </CaptureContextProvider>
  );
}

export const EntryPointsForRoutes: IPointsForRoutes = {
  id: RouteNames.IDIntro,
  bvn: RouteNames.BVNIntro,
  selfie: RouteNames.takeSelfie,
};

export const ExitPointsForRoutes: IPointsForRoutes = {
  id: RouteNames.idReceived,
  bvn: RouteNames.enterBVN,
  selfie: RouteNames.captureSuccess,
};
