import './HeaderContent.css';

export default function HeaderContent({ title = '', subTitle = '' }) {
  return (
    <header className="header-content">
      <h2 className="title">{title}</h2>
      {subTitle && <p className="sub-title">{subTitle}</p>}
    </header>
  );
}
