import useImages from "../../../../core/hooks/useImages.ts";

export default function LoaderComponent(){
    const {loadingGif} = useImages();
    return (
        <figure style={{display: "flex", alignItems: "center"}} className="gif-container">
            <img style={{width: '6rem', height: '6rem'}} src={loadingGif} alt="loader"/>
        </figure>
    )
}
