/* eslint-disable consistent-return */
import {useRef, useEffect, MutableRefObject} from 'react';

const useThunkEffectCalls = (callback: () => void) => {
    const effectRun: MutableRefObject<boolean> = useRef(false);

    useEffect(() => {
        if (!effectRun.current) {
            callback();

            return () => {
                effectRun.current = true;
            };
        }
    }, []);
    return {};
};

export default useThunkEffectCalls;
