import HeaderContent from "../headerContent/HeaderContent.tsx";
import ContentWrapper from "../contentWrapper/ContentWrapper.tsx";
import './FeedbackComplete.css';
import useIcon from "../../../../core/hooks/useIcon.ts";
import FeedbackTick from '../../../../core/assets/images/feedback__tick.svg?react'

export default function FeedbackComplete ():JSX.Element{
    const {infoCircleIcon} = useIcon();
    return (
        <ContentWrapper className="feedback-complete-container">
            <figure>
                <FeedbackTick />
            </figure>
            <section className="other__content">
                <HeaderContent
                    title="Thank you for your feedback"
                    subTitle="Your feedback has been noted. "/>

                <aside className="bottom__instruct">
                    <figure>
                        <img src={infoCircleIcon} alt="Info Circle Icon"/>
                    </figure>
                    <span>
                        Kindly proceed to close this window and restart the KYC process
                    </span>
                </aside>
            </section>
        </ContentWrapper>
    )
}
