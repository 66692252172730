/* eslint-disable  @typescript-eslint/no-explicit-any */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Buffer, createCipheriv, createDecipheriv } from 'browser-crypto';
const reactappencryptionkey = import.meta.env.VITE_REACT_APP_ENCRYPTION_KEY;

export const getBaseUrlFromEnv = (): string => {
    const urlFromEnvironment = {
        // development: 'https://staging.roqqu.com/v2',
        development: 'https://api.roqqupay.com/v2.5',
        production: 'https://api.roqqupay.com/v2.5',
        testing: 'https://staging.roqqupay.com/v2.5',
    }
    const env = process.env.NODE_ENV;
    return urlFromEnvironment[env as keyof typeof urlFromEnvironment];
}

export const attachQueryFieldsToUrl = (url: string, parameters: object) => {
    let finalUrl = `${url}?`;
    const parameterNames = Object.keys(parameters);
    for (let i = 0; i < parameterNames.length; i++) {
        const param = parameterNames[i];
        const value = parameters[param as keyof typeof parameters];
        if (i === 0) {
            finalUrl += `${param}=${value}`;
        } else {
            finalUrl += `&${param}=${value}`;
        }
    }
    return finalUrl;
}

export const consoleLog = (args: any) => {
    const env = process.env.NODE_ENV;
    if (env === 'development') {
        console.log(args);
    }
}


export function truncateFileName(fileName: string, prefixLength = 6, suffixLength = 6) {
        if (fileName.length < prefixLength + suffixLength) {
            return fileName;
        }

        const prefix = fileName.slice(0, prefixLength);
        const suffix = fileName.slice(-suffixLength);

        return `${prefix}...${suffix}`;
}

export const convertFileSizeToKBFromBytes = (fileSize: number) => {
    return Math.round(fileSize / 1000);
}


export function getCurrencyIconUrl(currencySymbol:string) {
    if (!currencySymbol) return '';
    return `https://roqqupay.com/static/media/currencies/${currencySymbol.toLowerCase()}.png`;
}


export enum LoadingState {
    loading = 'loading',
    error = 'error',
    successful = 'successful',
    base = 'base'
}

export enum DocumentType {
    capture = 'capture',
    upload = 'upload',
}

export const convertFileToBase64 = (file: File) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result?.toString());
        reader.onerror = error => reject(error);
    });
}

export function blobToFile(theBlob: Blob, fileName: string): File {
    return new File([theBlob], fileName, { lastModified: new Date().getTime(), type: theBlob.type });
}

export const dataURLtoFile = (dataurl: any, filename:any) => {
    const arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[arr.length - 1]),
        n = bstr.length;

    const u8arr = new Uint8Array(n);

    for (let i = 0; i < n; i++) {
        u8arr[i] = bstr.charCodeAt(i);
    }

    return new File([u8arr], filename, { type: mime });
};

export function downloadVideo(file:File) {
    const downloadLink = document.createElement('a');
    downloadLink.href = URL.createObjectURL(file);
    downloadLink.download = file.name;

    // Append the link to the body
    document.body.appendChild(downloadLink);

    // Trigger a click event to start the download
    downloadLink.click();

    // Remove the link from the DOM
    document.body.removeChild(downloadLink);
}

export function blobToDataURL(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = () => {
            resolve(reader.result as string);
        };

        reader.onerror = reject;

        reader.readAsDataURL(blob);
    });
}


const capitalizeFirstWord = (sentence: string): string => {
    const words = sentence.split(' ');
    const firstWord = words[0];
    const firstWordToUpperCase = firstWord.toUpperCase();
    const capitalizedWord = firstWordToUpperCase.substring(0,1) + firstWord.substring(1);
    words.splice(0, 1, capitalizedWord);
    const capitalizedSentence = words.join(' ');
    return capitalizedSentence;
}

export const getCustomMessageFromError = ({ message }: Error): string => {
    const GENERIC_MESSAGE = 'We don’t know where the issue is';
    const GENERIC_NETWORK_ERROR = 'Network error. Please check your internet connection';
    if (message.startsWith('(')) return GENERIC_MESSAGE;
    return message.includes('undefined') ? GENERIC_NETWORK_ERROR: capitalizeFirstWord(message);
}

export function _generateRandomIV() {
    let code = '';
    const possible = '0123456789';

    for (let i = 0; i < 16; i += 1) {
        code += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return code;
}
// encrypt
export function encrypt(rawString: any) {
    const randomIV = _generateRandomIV();
    const iv = Buffer.from(randomIV);
    const data = Buffer.from(rawString);
    const cipher = createCipheriv('aes-256-ctr', reactappencryptionkey, iv);
    const encrypted = Buffer.concat([cipher.update(data), cipher.final()]);

    const encryptedHex = encrypted.toString('hex');
    const ivHex = iv.toString('hex');

    return encryptedHex.slice(0, 10) + ivHex + encryptedHex.slice(10, encryptedHex.length);
}
// decrypt
export function decrypt(hash:string) {
    const iv = hash.slice(10, 42);
    const content = hash.slice(0, 10) + hash.slice(42, hash.length);

    const decipher = createDecipheriv('aes-256-ctr', reactappencryptionkey, Buffer.from(iv, 'hex'));
    const decrypted = Buffer.concat([decipher.update(Buffer.from(content, 'hex')), decipher.final()]);

    return decrypted.toString();
}
