import './ModalLayout.css';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Modal from 'react-awesome-modal';
import Portal from "../../containers/portal/Portal.tsx";
import { ReactNode } from 'react';

type modalLayoutType = {
    visible: boolean,
    children: ReactNode,
    onClose: () => void,
    width: string

}

function ModalLayout({
         visible,
         children,
         onClose = () => {},
         width = '45.8rem',
         }: modalLayoutType) {
    function closeModal() {
        onClose && onClose();
    }

    return (
        <Portal selector="#modal">
            <div
                onClick={(event) => event.stopPropagation()}
                className="modal-parent"
                // style={{ '--width': width }}
            >
                <Modal
                    effect="fadeInUp"
                    onClickAway={() => closeModal()}
                    visible={visible}
                >
                    {visible && (
                        <div
                            className="modal-wrap"
                            style={{ width: '100%', maxWidth: width }}
                        >
                            {children}
                        </div>
                    )}
                </Modal>
            </div>
        </Portal>
    );
}


export default ModalLayout;
