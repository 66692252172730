/* eslint-disable @typescript-eslint/no-explicit-any */
import './CaptureFace.css';
import ContentWrapper from '../../components/contentWrapper/ContentWrapper.tsx';
import CaptureFaceComponent from '../../components/captureFaceComponent/captureFaceComponent.tsx';
import { createContext, useContext } from 'react';
import { VideoTestContextProvider } from '../../../../core/context/VideoTestContextProvider.tsx';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../core/store';
import ErrorComponent from '../../../../core/containers/errorComponent/ErrorComponent.tsx';

type MessageContextType = {
  message: string | null; // Replace 'any' with the type of your shared state
  setMessage: React.Dispatch<React.SetStateAction<any>>;
};

type ErrorContextType = {
  error: string | null; // Replace 'any' with the type of your shared state
  setError: React.Dispatch<React.SetStateAction<string | null>>;
};

type StepContextType = {
  step: number | null; // Replace 'any' with the type of your shared state
  setStep: React.Dispatch<React.SetStateAction<number | null>>;
};

export const MessageContext = createContext<MessageContextType | null>(null);
export const ErrorContext = createContext<ErrorContextType | null>(null);
export const StepsContext = createContext<StepContextType | null>(null);

export function useMessageContext() {
  const context = useContext(MessageContext);
  if (!context) {
    throw new Error(
      'useMessageContext must be used within a MessageContextProvider',
    );
  }
  return context;
}

function CaptureFace() {
  const { vid } = useSelector((state: RootState) => state.verification);
  // const startOver = () => {
  //   setStep(null);
  //   setMessage("Position your face within the frame");
  // }

  return vid ? (
    <ContentWrapper className="fade-in">
      <div className="capture-container">
        <figure className="capture-face">
          <VideoTestContextProvider>
            <CaptureFaceComponent />
          </VideoTestContextProvider>
        </figure>
        <div className="capture-face-container"></div>
      </div>
    </ContentWrapper>
  ) : (
    <ErrorComponent errorMsg="Invalid Link" />
  );
}

export default CaptureFace;
