import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import {RootState} from "../../../../core/store";
import RouteNames from "../../../../core/routes/routenames.ts";
import InstructionComponent from "../../components/instructionComponent/InstructionComponent.tsx";
import './BVNIntro.css';
import ErrorComponent from "../../../../core/containers/errorComponent/ErrorComponent.tsx";
import {getCountryFromStorage} from "../../persistenceService.ts";
import {useTranslation} from "react-i18next";
import UploadGovtID from '../../../../core/assets/images/uploadGovtID.svg?react';

export function BVNIntro(){
    const {t} = useTranslation();
    const router = useNavigate();
    const country = useSelector((state : RootState) => state.verification.country);
    const routeToEnterDetailsPage = () => {
        router(RouteNames.enterYourDetails);
    }
    const pageDetails = {
        headerContentTitle: t('bvnIntro.headerContentTitle'),
        headerContentSubTitle: t('bvnIntro.headerContentSubTitle'),
        buttonReuseText: t('bvnIntro.buttonReuseText'),
        action: routeToEnterDetailsPage,
        img: <UploadGovtID />,
    }

    return(
        (getCountryFromStorage() || country)?
            <>
                <InstructionComponent pageDetails={pageDetails}/>
            </> :
         <ErrorComponent errorMsg="No country selected"/>
    )
}
