/**
 * This function capitalize the first letter of a string
 */
export default function capitalizeFirst(string: string) {
  if (typeof string !== 'string') {
    return string;
  }
  const arr = string.split(' ');
  arr[0] = arr[0].charAt(0).toUpperCase() + arr[0].slice(1);
  return arr.join(' ');
}
