import './ErrorComponent.css';
import ButtonReuse from "../../uikit/button/Button.tsx";
import {cleanUpStorage} from "../../../features/ng-verification/persistenceService.ts";
import { useTranslation } from 'react-i18next';
import ErrorFigure from '../../assets/images/error-figure.svg?react';

interface ErrorComponentProps {
  errorMsg: string
}

export default function ErrorComponent({ errorMsg }: ErrorComponentProps) {
  const { t } = useTranslation();

  function goBackToHome() {
    cleanUpStorage();
    window.close();
  }

  return (
    <main className="error-component">
        <figure className="">
            <ErrorFigure/>
        </figure>
        <h4 className="error-message">{errorMsg}</h4>
        <ButtonReuse
            onClick={() => goBackToHome()}
            btnStyle="btn-reuse"
            text={t('errorComponent.btnText')}
            style={{margin: 'auto'}}
        />
    </main>
  )
}
