import './DocumentCaptureMode.css';
import {setDocumentCaptureMode} from "../../verificationSlice.ts";
import {DocumentType} from "../../../../core/utils/helpers.ts";
import RouteNames from "../../../../core/routes/routenames.ts";
import useIcon from "../../../../core/hooks/useIcon.ts";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import useImages from "../../../../core/hooks/useImages.ts";
import {RootState} from "../../../../core/store";
import ErrorComponent from "../../../../core/containers/errorComponent/ErrorComponent.tsx";
import ContentWrapper from "../../components/contentWrapper/ContentWrapper.tsx";
import {getAllowedIDsFromStorage} from "../../persistenceService.ts";
import {useTranslation} from "react-i18next";

export default function DocumentCaptureMode() {
    const {allowedIDs} = useSelector((state : RootState) => state.verification);
    const dispatch = useDispatch();
    const router = useNavigate();
    const {uploadIconBlue, chevronIcon, cameraIcon} = useIcon();
    const {selectCaptureType} = useImages();
    const {t} = useTranslation();
    const options = [
        {
            icon: uploadIconBlue,
            iconAlt: "upload icon",
            title: t('documentCaptureMode.option1'),
            action: () => {
                dispatch(setDocumentCaptureMode(DocumentType.upload))
                router(RouteNames.uploadId)
            },
        },
        {
            icon: cameraIcon,
            iconAlt: "camera icon",
            title: t('documentCaptureMode.option2'),
            action: () => {
                dispatch(setDocumentCaptureMode(DocumentType.capture))
                router(RouteNames.captureID)
            }
        }
    ]
    return(
        (allowedIDs || getAllowedIDsFromStorage())?
        <ContentWrapper className="document-capture-wrapper fade-in">
                <section>
                <figure className="main-content-img">
                    <img src={selectCaptureType} alt=""/>
                </figure>
                <div className="main-content-wrapper">
                    <h4>{t('documentCaptureMode.title')}</h4>
                    <div className="main-content-options">
                        {
                            options.map((item, index) => (
                                <article onClick={item.action} key={index}>
                                    <figure className="left-icon-figure">
                                        <img src={item.icon} alt={item.iconAlt}/>
                                    </figure>
                                    <span>{item.title}</span>
                                    <figure className="chevron">
                                        <img src={chevronIcon} alt={item.iconAlt}/>
                                    </figure>
                                </article>
                            ))
                        }
                    </div>
                </div>
            </section>
            </ContentWrapper> :
        <ErrorComponent errorMsg={t('commonErrorText')}/>
    )
}
