const RouteNames = {
  home: '/',
  getVerified: '/get-verified',
  IDIntro: '/id-intro',
  BVNIntro: '/bvn-intro',
  enterBVN: '/enter-bvn',
  uploadId: '/upload-id',
  capture: '/capture',
  captureID: '/capture-id',
  captureIDSuccess: '/capture-id-success',
  captureFace: '/capture-face',
  videoConfirmation: '/video-confirmation',
  captureSuccess: '/capture-success',
  takeSelfie: '/take-selfie',
  verificationComplete: '/verification-complete',
  verificationFailed: '/verification-failed',
  documentCaptureMode: '/document-capture-mode',
  selectOfficialDocument: '/select-official-document',
  enterYourDetails: '/enter-your-details',
  idReceived: '/id-received',
  fixABlankScreen: '/fix-a-blank-screen',
  dropFeedback: '/drop-feedback',
  feedbackComplete: '/feedback-complete',
};

export const eurRouteNames = {
  europe: '/eur',
  selectADocumentToUpload: '/eur/select-a-document-to-upload',
  documentCaptureMode: '/eur/document-capture-mode',
  uploadId: '/eur/upload-id',
  captureID: '/eur/capture-id',
  captureIDSuccess: '/eur/capture-id-success',
  languageSelection: '/eur/language-selection',
  verificationProcessing: '/eur/verification-processing',
  verificationComplete: '/eur/verification-complete',
};
export default RouteNames;
