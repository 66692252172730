import './GetVerified.css';
import ButtonReuse from "../../../../core/uikit/button/Button.tsx";
import HeaderContent from "../../components/headerContent/HeaderContent.tsx";
import ContentWrapper from "../../components/contentWrapper/ContentWrapper.tsx";
import {useNavigate} from "react-router-dom";
import useIcon from "../../../../core/hooks/useIcon.ts";
import {useSelector} from "react-redux";
import {RootState} from "../../../../core/store";
import ErrorComponent from "../../../../core/containers/errorComponent/ErrorComponent.tsx";
import useVerification from "../../../../core/hooks/useVerification.ts";
import {LoadingState} from "../../../../core/utils/helpers.ts";
import LoaderComponent from "../../components/loaderComponent/LoaderComponent.tsx";
import GetStartedImage from "../../../../core/assets/images/get-started.svg?react"

export function GetVerified() {
    const {padlockIcon} = useIcon();
    const router = useNavigate();
    const {createVerificationLoadingState, vid, createVerificationError} = useSelector((state: RootState) => state.verification);
    const {pageDetails, currentEntryPoint} = useVerification();

    const determineNextPage = () => {
       router(currentEntryPoint);
    }


    return (
            (createVerificationLoadingState === LoadingState.loading) ?
            <LoaderComponent/> :
            (createVerificationLoadingState === LoadingState.error || !vid )?
            <ErrorComponent errorMsg={createVerificationError! || "There was an error processing this request. Try again later."}/>:
            <ContentWrapper className="get-verified-wrapper fade-in">
                <figure className="get-verified-image-wrapper">
                    <GetStartedImage />
                </figure>


                <HeaderContent
                    title="What we need to start"
                    subTitle="These are the following documents we will need to verify your identity please get them ready."
                />

                {
                    pageDetails && pageDetails.map((item, index) => (
                    <section className="page-details" key={index}>
                        <div className="details-item">
                            <figure>
                                <img src={item.icon} alt={item.iconAlt}/>
                            </figure>
                            <div>
                                <h4>{item.title}</h4>
                                <p>{item.description}</p>
                            </div>
                        </div>
                    </section>
                ))
                }

            <footer>
                <small>
                    <figure>
                        <img src={padlockIcon} alt="padlock"/>
                    </figure>
                    <span>Your info would be encrypted and stored securely</span>
                </small>
                <section className="button-container">
                    <ButtonReuse onClick={determineNextPage} btnStyle="btn-reuse" text="Get Started" />
                </section>
            </footer>

        </ContentWrapper>

    )
}
