/* eslint-disable @typescript-eslint/no-explicit-any */
import { useRef, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

export default function Portal({ children, selector = '#modal' }: any) {
    const ref = useRef(null);
    const [mounted, setMounted] = useState(false);

    useEffect(() => {
        ref.current = document.querySelector(selector);
        setMounted(true);
    }, [selector]);

    return mounted && ref.current ? createPortal(children, ref!.current) : null;
}
