import useTheme, { ThemeMode } from './useTheme.ts';
import loadingCircle from '../assets/icons/loader-icon.svg';
import uploadIcon from '../assets/icons/upload.svg';
import uploadIconBlue from '../assets/icons/upload-blue.svg';
import chevronIcon from '../assets/icons/chevron.svg';
import fileIcon from '../assets/icons/file.svg';
import _fileIcon from '../assets/icons/_passport.svg';
import trashIcon from '../assets/icons/trash.svg';
import _trashIcon from '../assets/icons/_trash.svg';
import searchIcon from '../assets/icons/search.svg';
import errorExclamationIcon from '../assets/icons/error-exclamation-icon.svg';
import bankIcon from '../assets/icons/bank.svg';
import cameraIcon from '../assets/icons/camera.svg';
import padlockIcon from '../assets/icons/padlock.svg';
import cancelIcon from '../assets/icons/cancel-icon.svg';
import creditCardIcon from '../assets/icons/credit-card.svg';
import passPortIcon from '../assets/icons/passport.svg';
import driverLicenseIcon from '../assets/icons/driver-license.svg';
import checkMarkIcon from '../assets/icons/check-mark.svg';
import infoCircleIcon from '../assets/icons/Info__Circle.svg';
import playIcon from '../assets/icons/play.svg';
import locationIcon from '../assets/icons/location.svg';
import chevDown from '../assets/icons/chev-down.svg';
import newToastWarning from '../assets/icons/toast-new/toast-new-warning.svg';
import newToastSuccess from '../assets/icons/toast-new/toast-new-success.svg';
import newToastError from '../assets/icons/toast-new/toast-new-error.svg';
import newToastPending from '../assets/icons/toast-new/toast-new-pending.svg';
import newToastClose from '../assets/icons/toast-new/toast-new-close.svg';

const commonIcons = {
  loadingCircle,
  uploadIcon,
  fileIcon,
  trashIcon,
  _trashIcon,
  searchIcon,
  errorExclamationIcon,
  bankIcon,
  cameraIcon,
  padlockIcon,
  cancelIcon,
  uploadIconBlue,
  chevronIcon,
  creditCardIcon,
  passPortIcon,
  driverLicenseIcon,
  checkMarkIcon,
  _fileIcon,
  infoCircleIcon,
  playIcon,
  locationIcon,
  chevDown,
  newToastWarning,
  newToastSuccess,
  newToastError,
  newToastPending,
  newToastClose,
};

const lightThemeIcons = {};

const darkThemeIcons = {};

export default function useIcon() {
  const { theme } = useTheme();
  return theme === ThemeMode.LIGHT
    ? { ...commonIcons, ...lightThemeIcons }
    : { ...commonIcons, ...darkThemeIcons };
}
