import useIcon from "../../../hooks/useIcon.ts";

function InputErrorMessage({ errorMessage }: { errorMessage: string}) {
    const { errorExclamationIcon } = useIcon();
    return (
        <>
            <figure className="img-box error-img-box">
                <img src={errorExclamationIcon} className="img" alt="excalamtion icon" />
            </figure>
            <div className="error-message error error-text">{errorMessage}</div>
        </>
    );
}

export default InputErrorMessage;
