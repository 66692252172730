import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../core/store";
import {useNavigate} from "react-router-dom";
import ErrorComponent from "../../../../core/containers/errorComponent/ErrorComponent.tsx";
import ContentWrapper from "../../components/contentWrapper/ContentWrapper.tsx";
import './CaptureIDSuccess.css';
import useIcon from "../../../../core/hooks/useIcon.ts";
import ButtonReuse from "../../../../core/uikit/button/Button.tsx";
import { dataURLtoFile, DocumentType, LoadingState} from "../../../../core/utils/helpers.ts";
import RouteNames from "../../../../core/routes/routenames.ts";
import {useEffect} from "react";
import {sendCustomerIDThunk} from "../../verificationThunks.ts";
import {useTranslation} from "react-i18next";

export default function CaptureIDSuccess (){
    const router = useNavigate();
    const dispatch = useDispatch();
    const {
        documentType,
        documentCaptureMode,
        capturedID,
        uploadedID,
        vid,
        uploadIDLoadingState,
    } = useSelector((state : RootState) => state.verification);
    const {checkMarkIcon} = useIcon();
    const {t} = useTranslation();


    const retakePhoto = () => {
        if (documentCaptureMode === DocumentType.capture){
            router(RouteNames.captureID);
        } else if (documentCaptureMode === DocumentType.upload){
            router(RouteNames.uploadId);
        }
    }

    const handleIDUpload = async () => {
        const file = documentCaptureMode === DocumentType.capture ? capturedID : uploadedID;
        const userId =  dataURLtoFile(file, 'customerID');

        const data = new FormData();
        data.append('vid', vid!);
        data.append('type', documentType!);
        data.append('id', userId!);

        dispatch<any>(sendCustomerIDThunk(data))
    }

    const usePhoto = () => {
        handleIDUpload();
    }

    const questionList = [
        t('captureIDSuccess.questionList.init'),
        t('captureIDSuccess.questionList.final'),
    ]

    useEffect(() => {
        if (uploadIDLoadingState === LoadingState.successful){
            router(RouteNames.idReceived)
        }
    }, [uploadIDLoadingState]);

    return (
        (uploadedID || capturedID)?
            <>
                <ContentWrapper className="capture-id-success-wrapper fade-in">
                    <div>
                        <h3 className="title">{t('captureIDSuccess.title')}</h3>
                        <ul className="question-list">
                            {
                                questionList.map((question, index) => (
                                    <li key={index}>
                                        <img src={checkMarkIcon} alt="Check Mark Icon"/>
                                        <span className="question">{question}</span>
                                    </li>
                                ))
                            }
                        </ul>
                    </div>
                    <figure className="canvas-result">
                        {
                            uploadedID ? (
                                <img className="result-img"
                                    src={uploadedID}
                                    alt="Image of capture result"
                                />
                            ) : (
                                capturedID && (
                                    <img
                                        src={capturedID}
                                        alt="Image of capture result"
                                    />
                                )
                            )
                        }

                    </figure>
                    <footer className="capture-id-success-footer">
                        <ButtonReuse loading={uploadIDLoadingState === LoadingState.loading} onClick={usePhoto} btnStyle="btn-reuse" style={{width: '100%'}} text="Use Photo"/>
                        <ButtonReuse onClick={retakePhoto} btnStyle="btn-link" text="Retake Photo"/>
                    </footer>
                </ContentWrapper>
            </>
             :
            <ErrorComponent errorMsg="Invalid Link"/>
    );
}
