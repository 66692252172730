import './SelectOfficialDocument.css';
import {setDocumentType} from "../../verificationSlice.ts";
import RouteNames from "../../../../core/routes/routenames.ts";
import useIcon from "../../../../core/hooks/useIcon.ts";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import useImages from "../../../../core/hooks/useImages.ts";
import {RootState} from "../../../../core/store";
import ErrorComponent from "../../../../core/containers/errorComponent/ErrorComponent.tsx";
import ContentWrapper from "../../components/contentWrapper/ContentWrapper.tsx";
import {getAllowedIDsFromStorage} from "../../persistenceService.ts";
import {IAllowedID} from "../../../../core/types";

export default function SelectOfficialDocument() {
    const { allowedIDs} = useSelector((state : RootState) => state.verification);
    const dispatch = useDispatch();
    const router = useNavigate();
    const {chevronIcon, creditCardIcon, driverLicenseIcon, passPortIcon} = useIcon();
    const {selectOfficialDocument} = useImages();

    const handleDocumentCaptureSelection = (key: string) => {
        dispatch(setDocumentType(key));
        router(RouteNames.documentCaptureMode);
    }

    const selectRandomIcon = () => {
        const icons = [passPortIcon, creditCardIcon, driverLicenseIcon];
        const rand = Math.floor(Math.random() * icons.length);
        return icons[rand];
    }
    return(
        (allowedIDs || getAllowedIDsFromStorage()) ?
            <>
                <ContentWrapper className="select-official-document fade-in">
                    <section>
                        <figure className="main-content-img">
                            <img src={selectOfficialDocument} alt=""/>
                        </figure>
                        <div className="main-content-wrapper">
                            <h4>Select your official document</h4>
                            <p>
                                We need a valid document to confirm and verify who you are. Data is processed securely.
                            </p>
                            <div className="main-content-options">
                                {
                                    (allowedIDs || getAllowedIDsFromStorage()).map((item:IAllowedID) => (
                                        <article style={{cursor: "pointer"}} onClick={() => handleDocumentCaptureSelection(item.key)} key={item.key}>
                                            <figure>
                                                <img src={selectRandomIcon()} alt={item.key}/>
                                            </figure>
                                            <span>{item.title}</span>
                                            <figure className="chevron">
                                                <img src={chevronIcon} alt="Chevron Icon"/>
                                            </figure>
                                        </article>
                                    ))
                                }
                            </div>
                        </div>
                    </section>
                </ContentWrapper>
            </>:
            <ErrorComponent errorMsg="Invalid Link"/>
    )
}
