/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import HeaderContent from '../../components/headerContent/HeaderContent.tsx';
import { useNavigate } from 'react-router-dom';
import ContentWrapper from '../../components/contentWrapper/ContentWrapper.tsx';
import { useCaptureContext } from '../../../../core/context/CaptureContextProvider.tsx';
import ErrorComponent from '../../../../core/containers/errorComponent/ErrorComponent.tsx';
import { useTranslation } from 'react-i18next';
import ButtonReuse from '../../../../core/uikit/button/Button.tsx';
import RouteNames from '../../../../core/routes/routenames.ts';
import { useEffect, useRef, useState } from 'react';
import useIcon from '../../../../core/hooks/useIcon.ts';
import './VideoConfirmation.css';

function VideoConfirmation() {
  const { customerRecording } = useCaptureContext();
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const router = useNavigate();
  const { t } = useTranslation();
  const { playIcon } = useIcon();

  const determineNextPage = () => {
    router(RouteNames.captureSuccess);
  };

  const playVideo = () => {
    videoRef.current!.play();
    setIsPlaying(true);
  };

  useEffect(() => {
    const videoElement = videoRef.current!;
    if (!videoElement) return;

    videoElement.addEventListener('ended', () => setIsPlaying(false));

    return () => {
      videoElement.removeEventListener('ended', () => setIsPlaying(false));
    };
  });

  return customerRecording ? (
    <ContentWrapper className="video-confirmation-wrapper fade-in">
      <div>
        <figure className="video-confirmation-image-wrapper">
          <video autoPlay loop ref={videoRef} width="320" height="240">
            <source src={customerRecording} type="video/mp4" />
            Your browser does not support the video tag.
          </video>

          {!isPlaying && (
            <div className="play-icon-container">
              <span onClick={playVideo}>
                <img src={playIcon} alt="Cancel Icon" />
              </span>
            </div>
          )}
        </figure>

        <HeaderContent
          title="Video Recording"
          subTitle="Please make sure the video is well recorded and click submit if its good to go or click start over if its not."
        />
      </div>

      <footer>
        <section className="button-container">
          <ButtonReuse
            onClick={determineNextPage}
            btnStyle="btn-reuse"
            text="Submit"
          />

          <ButtonReuse
            onClick={() => router(RouteNames.captureFace)}
            btnStyle="btn-link"
            style={{ textTransform: 'none', marginTop: '1rem' }}
            text="Start over"
          />
        </section>
      </footer>
    </ContentWrapper>
  ) : (
    <ErrorComponent errorMsg={t('commonErrorText')} />
  );
}

export default VideoConfirmation;
