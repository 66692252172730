/* eslint-disable @typescript-eslint/no-explicit-any */

import axios from 'axios';
import {decrypt, encrypt, getBaseUrlFromEnv} from './helpers.ts';

const defaultAxiosInstance = axios.create({
  baseURL: getBaseUrlFromEnv(),
});

export const videoUploadAxiosInstance = axios.create({
  baseURL: 'https://upload-processor.roqqupay.com',
});


async function requestInterceptorSuccessCB(successfulReq:any) {
  if (successfulReq.method === 'post' || successfulReq.method === 'POST') {
      const JSONData = JSON.stringify(successfulReq.data);
      const data = encrypt(JSONData);
      successfulReq.data = {
          data,
      };
  }
  return successfulReq;
}

// Request Error
function requestInterceptorErrorCB(error:any) {
  error.response = { ...error.response, data: JSON.parse(decrypt(error.response.data)) };
  return Promise.reject(error);
}

// Response interceptor callbacks
// Response Success
function responseInterceptorSuccessCB(successRes:any) {
  successRes.data = JSON.parse(decrypt(successRes.data));
  return successRes;
}

// Response Error
function responseInterceptorErrorCB(error:any) {
  error.response = { ...error.response, data: JSON.parse(decrypt(error.response.data)) };
  return Promise.reject(error);
}

(() => {
  // if (process.env.NODE_ENV !== 'test' && process.env.NODE_ENV !== 'development') {
    // Request interceptor
    defaultAxiosInstance.interceptors.request.use(requestInterceptorSuccessCB, requestInterceptorErrorCB);

    // Response interceptor
    defaultAxiosInstance.interceptors.response.use(responseInterceptorSuccessCB, responseInterceptorErrorCB);
  // }
})();

export default defaultAxiosInstance;
