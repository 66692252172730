import ButtonReuse from '../../../../core/uikit/button/Button.tsx';
import HeaderContent from '../../components/headerContent/HeaderContent.tsx';
import ContentWrapper from "../../components/contentWrapper/ContentWrapper.tsx";
import useImages from "../../../../core/hooks/useImages.ts";

function VerificationFailed() {
    const {failedVerification} = useImages();
    const startAgain = () => {
        window.location.reload();
    }
  return (
    <ContentWrapper className="verification-complete-wrapper fade-in">
      <div className="capture-container">
        <figure  style={{marginTop: '12rem', marginBottom: '3rem', width: "13rem", height: "13rem"}}>
          <img
            src={failedVerification}
            style={{
              width: '100%',
              height: '100%',
             objectFit: 'cover',
            }}
            className="img"
            alt=""
          />
        </figure>
        <div>
          <HeaderContent
            title="Your selfie verification failed"
            subTitle="We’re sorry to inform you that your selfie verification has failed. Please ensure that the photo is clear and matches the provided ID. You can try again."
          />
        </div>
        <div style={{marginTop: "auto"}} className="cta-section">
          <ButtonReuse onClick={startAgain} btnStyle="btn-reuse" text="Start Again" />
        </div>
      </div>
    </ContentWrapper>
  );
}

export default VerificationFailed;
