/* eslint-disable @typescript-eslint/no-explicit-any */
import ContentWrapper from "../contentWrapper/ContentWrapper.tsx";
import HeaderContent from "../headerContent/HeaderContent.tsx";
import ButtonReuse from "../../../../core/uikit/button/Button.tsx";
import './InstructionComponent.css';
import {useSelector} from "react-redux";
import {RootState} from "../../../../core/store";
import CaptureIDComponent from "../captureIdComponent/CaptureIDComponent.tsx";
import {DocumentType} from "../../../../core/utils/helpers.ts";
import GetHelpComponent from "../getHelpComponent/GetHelpComponent.tsx";
import routenames from "../../../../core/routes/routenames.ts";

export default function InstructionComponent
({
     pageDetails,
     canvasType = 'image',
     additionalBtn = false,
     additionalBtnDetails = {},
     getHelp = {},
 }: any) {

    const {uploadedID, capturedID} = useSelector((state : RootState) => state.verification);
    const returnCanvas = () => {
        if(canvasType === 'image'){
            return (
                <figure style={pageDetails.imgWrapperStyle ? pageDetails.imgWrapperStyle : null} className="instruction-image-wrapper">
                    {/* <img src={pageDetails.img} alt="Image of a Document"/> */}
                    {pageDetails.img}
                </figure>
            )
        } else if(canvasType === 'result'){
            return (
                <figure className="instruction-canvas-result">
                    {
                        ( document || capturedID ) &&
                        (
                            <img
                                className = {DocumentType.capture === "capture" ? "invert-image" : ""}
                                src={ DocumentType.capture === "capture" ? capturedID || "" : uploadedID || ""}
                                alt="Image of capture result"/>
                        )
                    }
                </figure>
            )
        } else if(canvasType === 'capture'){
            return <CaptureIDComponent/>
        }
    }
    return(
        <ContentWrapper className="instruction-wrapper fade-in">
            {
                returnCanvas()
            }
            <HeaderContent
                title={pageDetails.headerContentTitle}
                subTitle={pageDetails.headerContentSubTitle}
            />

            <footer>
                <section className="button-container">
                    <ButtonReuse
                        loading={pageDetails?.loading}
                        onClick={pageDetails.action}
                        btnStyle="btn-reuse"
                        text={pageDetails.buttonReuseText} />

                    {
                        additionalBtn &&  <ButtonReuse
                            onClick={additionalBtnDetails && additionalBtnDetails.action}
                            btnStyle="btn-link"
                            style={{textTransform: "none", marginTop: "1rem"}}
                            text= "Watch a how to video"/>
                    }

                    {
                        Object.keys(getHelp).length > 0 && (
                            <GetHelpComponent
                                text={getHelp.text}
                                action={getHelp.action}
                                routePage={routenames.fixABlankScreen}/>
                        )
                    }

                </section>
            </footer>

        </ContentWrapper>
    )
}
