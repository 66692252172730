import {useSelector} from "react-redux";
import {RootState} from "../../../../core/store";
import ErrorComponent from "../../../../core/containers/errorComponent/ErrorComponent.tsx";
import CaptureIDComponent from "../../components/captureIdComponent/CaptureIDComponent.tsx";
import ContentWrapper from "../../components/contentWrapper/ContentWrapper.tsx";
import './CaptureID.css';

export function CaptureID(){
    const country = useSelector((state : RootState) => state.verification.country);

    return(
        country?
            <ContentWrapper style={{padding: 0, width: '100%'}}
                            className="capture-wrapper fade-in">
                <CaptureIDComponent/>
            </ContentWrapper> :
            <ErrorComponent errorMsg="No country passed"/>
    )
}
