/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { ReactNode, useEffect } from 'react'
import {Outlet, useNavigate, useSearchParams} from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  setCountry,
  setMetadata,
  setVerificationRequirements, setVid
} from "../../features/ng-verification/verificationSlice.ts";

import useTheme, { ThemeMode } from "../hooks/useTheme.ts";
import ErrorComponent from "./errorComponent/ErrorComponent.tsx";
import {createVerificationIdentifierThunk} from "../../features/ng-verification/verificationThunks.ts";
import useThunkEffectCall from "../hooks/useThunkEffectCall.ts";
import RouteNames from "../routes/routenames.ts";
import {
  getCountryFromStorage,
  getMetaDataFromStorage,
  getRequiredFieldsFromStorage,
  getVerificationIdentifierFromStorage,
  persistCountry,
  persistMetaData, persistRequiredFields
} from "../../features/ng-verification/persistenceService.ts";

export default function NGMainContent() {
  const [params] = useSearchParams();
  const { theme, toggleTheme } = useTheme();
  const  dispatch = useDispatch();
  const router = useNavigate();


  useThunkEffectCall(()=>{
    const metaDataFromParams = params.get('metadata');
    const metaDataFromStorage = getMetaDataFromStorage();
    const metadataRef = metaDataFromStorage ?? metaDataFromParams;
    const vidFromStorage = getVerificationIdentifierFromStorage();
    const country = getCountryFromStorage() ?? params.get('country');

    dispatch(setMetadata(metadataRef!));
    persistMetaData(metadataRef!);

    if (vidFromStorage) {
      dispatch(setVid(vidFromStorage));
    }

    if (metaDataFromStorage) return
    dispatch<any>(createVerificationIdentifierThunk({metadata: metadataRef, country}))
  });


  useEffect(() => {
    const themeParameter = params.get('skin')  === ThemeMode.DARK ? ThemeMode.DARK: ThemeMode.LIGHT;
    const required = getRequiredFieldsFromStorage() ?? params.get('required');
    const country = getCountryFromStorage() ?? params.get('country');


    if (required) {
      persistRequiredFields(required);
      const __required = required.split(',')
      dispatch(setVerificationRequirements(__required));
    }

    if (country) {
      dispatch(setCountry(country));
      persistCountry(country);
      router(RouteNames.getVerified);
    }

    if (!themeParameter) {
      toggleTheme(theme);
      return;
    }

    if (themeParameter !== theme) {
      toggleTheme(themeParameter);
    }
  }, []);


  return (
    <div className="main-content">
      <ErrorBoundary>
        <Outlet/>
      </ErrorBoundary>
    </div>
  )
}

interface Props {
  children?: ReactNode
}

interface State {
  hasError: boolean
  error: Error
}

class ErrorBoundary extends React.Component<Props, State> {
  static getDerivedStateFromError (error: Error): State {
    return ({ hasError: true, error })
  }

  componentDidCatch (error: Error, info: React.ErrorInfo): void {
    if (process.env.NODE_ENV !== 'production') console.error('Uncaught error =>', error, info)
  }

  render (): ReactNode {
    if (this.state && this.state.hasError) {
      return <ErrorComponent errorMsg="Oops! something went wrong" />
    }

    return this.props.children
  }
}
