const verificationUrls = {
    createVerificationIdentifier: '/identity/verification/create',
    validateIdentifier: '/identity/verification/validate-id',
    sendCustomerRecording: '/identity/video/facematch',
    pollSelfieVerificationStatus: '/identity/verification',
    checkAllowedIDs: '/identity/verification/allowed-ids',
    sendCustomerID: 'identity/documentation/image/id',
    sendFeedback: '/identity/verification/provide-feedback',
};

export default verificationUrls;
