import './UploadID.css';
import ButtonReuse from "../../../../core/uikit/button/Button.tsx";
import {FileUpload} from "../../../../core/uikit/fileUpload/fileUpload.tsx";
import HeaderContent from "../../components/headerContent/HeaderContent.tsx";
import ContentWrapper from "../../components/contentWrapper/ContentWrapper.tsx";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import {RootState} from "../../../../core/store";
import ErrorComponent from "../../../../core/containers/errorComponent/ErrorComponent.tsx";
import RouteNames from "../../../../core/routes/routenames.ts";
import {getAllowedIDsFromStorage} from "../../persistenceService.ts";
export default function UploadID() {
    const router = useNavigate();
    const {  allowedIDs, uploadedID } = useSelector((state : RootState) => state.verification);
    const routeToCapture = () => {
        router(RouteNames.captureIDSuccess);
    }
    return(
        (allowedIDs || getAllowedIDsFromStorage()) ?
        <ContentWrapper className="upload-id-wrapper fade-in">
            <HeaderContent
                title="Upload your govt issued ID OR Ecowas ID"
                subTitle="To complete the verification process, please enter your government-issued ID"
            />
            <section className="upload-file-container">
                <FileUpload/>
            </section>
            <section className="button-container">
                <ButtonReuse isDisabled={!uploadedID} onClick={routeToCapture} btnStyle="btn-reuse" text="Proceed" />
            </section>
        </ContentWrapper>:
        <ErrorComponent errorMsg="No country passed"/>
    )
}
