// import ButtonReuse from '../../../../core/uikit/button/Button.tsx';
import HeaderContent from '../../components/headerContent/HeaderContent.tsx';
import ContentWrapper from "../../components/contentWrapper/ContentWrapper.tsx";
import useImages from "../../../../core/hooks/useImages.ts";
import {useSelector} from "react-redux";
import {RootState} from "../../../../core/store";
// import {cleanUpStorage} from "../../persistenceService.ts";

function VerificationComplete() {
    const {successfulIDUpload} = useImages();
    const {pollSelfieVerificationSuccessMessage} = useSelector((state : RootState) => state.verification);
    // const closeCurrentTab = () => {
    //     cleanUpStorage();
    //     window.close();
    // }
  return (
    <ContentWrapper className="verification-complete-wrapper fade-in">
      <div className="capture-container">
        <figure  style={{marginTop: '12rem', marginBottom: '3rem', width: "13rem", height: "13rem"}}>
          <img
            src={successfulIDUpload}
            style={{
              width: '100%',
              height: '100%',
             objectFit: 'cover',
            }}
            className="img"
            alt=""
          />
        </figure>
        <div>
          <HeaderContent
            title={pollSelfieVerificationSuccessMessage?.header}
            subTitle={pollSelfieVerificationSuccessMessage?.subTitle}
          />
            <p style={{
                fontWeight: 500,
                fontSize: "1.4rem",
                color: "rgba(115, 122, 145, 1)",
                marginTop: "4rem",
                textAlign: "center"
            }}>You can go ahead and close this page now</p>
        </div>
        {/*<div style={{marginTop: "auto"}} className="cta-section">*/}
        {/*  <ButtonReuse onClick={closeCurrentTab} btnStyle="btn-reuse" text="Done" />*/}
        {/*</div>*/}
      </div>
    </ContentWrapper>
  );
}

export default VerificationComplete;
