import { useEffect, useState } from "react";

export default function useCheckDeviceType (){
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    const isDesktop = !isMobile;
    const [orientation, setOrientation] = useState(getInitialOrientation());

  function getInitialOrientation() {
    if (isMobile) {
      const isPortrait = window.matchMedia('(orientation: portrait)').matches;
      return isPortrait ? 'Portrait' : 'Landscape';
    }
    return 'Not a mobile device';
  }

  useEffect(() => {
    const handleOrientationChange = () => {
      setOrientation(getInitialOrientation());
    };

    if (isMobile) {
      window.addEventListener('orientationchange', handleOrientationChange);
    }

    return () => {
      if (isMobile) {
        window.removeEventListener('orientationchange', handleOrientationChange);
      }
    };
  }, [isMobile]);

    return { isMobile, isDesktop, orientation};
}
