/* eslint-disable @typescript-eslint/no-explicit-any */
import { type AxiosInstance } from "axios";
import defaultAxiosInstance from "./axiosInstance.ts";
import { attachQueryFieldsToUrl } from "./helpers.ts";

interface HttpClient {
  axiosInstance: AxiosInstance
  get?: (url: string, queryParams?: object) => Promise<any>
  post?: (url: string, body: object, options?: object) => Promise<any>
}

export const newHttpClient = (instance: AxiosInstance = defaultAxiosInstance): HttpClient => {
  return (
    {
      axiosInstance: instance,
      get: async function(url: string, queryParams?: object): Promise<any> {
        let parsedUrl = url;
        if (queryParams !== undefined) parsedUrl = attachQueryFieldsToUrl(parsedUrl, queryParams);
        const res = await this.axiosInstance.get(parsedUrl);
        return res;
      },
      post: async function(url: string, body: object, options: object = {}): Promise<any> {
        const res = await this.axiosInstance.post(url, body, options);
        return res;
      }
    }
  )
}

