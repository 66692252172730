import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../core/store";
import RouteNames from "../../../../core/routes/routenames.ts";
import InstructionComponent from "../../components/instructionComponent/InstructionComponent.tsx";
import './IDIntro.css';
import ErrorComponent from "../../../../core/containers/errorComponent/ErrorComponent.tsx";
import {checkAllowedIDsThunk} from "../../verificationThunks.ts";
import {LoadingState} from "../../../../core/utils/helpers.ts";
import {useEffect} from "react";
import {getCountryFromStorage, getVerificationIdentifierFromStorage} from "../../persistenceService.ts";
import UploadGovtID from '../../../../core/assets/images/uploadGovtID.svg?react';

export function IDIntro(){
    const router = useNavigate();
    const {vid, country, checkAllowedIDsLoadingState} = useSelector((state : RootState) => state.verification);
    const dispatch = useDispatch();

    const checkAllowedIDs = () => {
        const countryData = country ?? getCountryFromStorage();
        dispatch<any>(checkAllowedIDsThunk({
            country: countryData,
        }));
    }

    useEffect(() => {
        if (checkAllowedIDsLoadingState === LoadingState.successful){
            router(RouteNames.selectOfficialDocument);
        }
    }, [checkAllowedIDsLoadingState]);

    const pageDetails = {
        headerContentTitle:  "Upload your government issued ID",
        headerContentSubTitle: "To continue the verification process, please upload your government-issued ID.",
        buttonReuseText: "Proceed",
        action:  checkAllowedIDs,
        img: <UploadGovtID />,
        buttonLoading: checkAllowedIDsLoadingState
    }

    return(
        (vid || getVerificationIdentifierFromStorage())?
        <InstructionComponent pageDetails={pageDetails}/>:
         <ErrorComponent errorMsg="Invalid Link"/>
    )
}
