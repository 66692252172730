import { useDispatch, useSelector } from 'react-redux';
import './toast.css';
import {
  closeToast,
  selectToastV2,
} from '../../../features/ng-verification/toastSlice.ts';
import Portal from '../../containers/portal/Portal';
import toastTypes from './toast-types.ts';
import useIcon from '../../hooks/useIcon.ts';

export default function ToastV2() {
  const {
    newToastError,
    newToastPending,
    newToastSuccess,
    newToastWarning,
    newToastClose,
  } = useIcon();
  const { isOpen, type, heading, description } = useSelector(selectToastV2);

  const dispatch = useDispatch();

  const bgColorFor = {
    [toastTypes.error]: '#F9B5B1',
    [toastTypes.pending]: '#9BB7FE',
    [toastTypes.success]: '#C7E9D7',
    [toastTypes.warning]: '#FCEED4',
  };

  function dismissToast(e: React.MouseEvent) {
    e.stopPropagation();
    dispatch(closeToast());
  }

  function interceptCloseEvent(e: React.MouseEvent) {
    e.stopPropagation();
  }

  return (
    <>
      {isOpen && (
        <Portal selector="#toast-p">
          <div className="toast-v2">
            <div className="backdrop" onClick={dismissToast}>
              <div className="toast-main">
                <div
                  className="toast-content"
                  style={{ background: bgColorFor[type] }}
                  onClick={interceptCloseEvent}
                >
                  <div className="top-row">
                    {type === toastTypes.warning && (
                      <img
                        src={newToastWarning}
                        alt=""
                        className="toast-icon"
                      />
                    )}
                    {type === toastTypes.error && (
                      <img src={newToastError} alt="" className="toast-icon" />
                    )}
                    {type === toastTypes.pending && (
                      <img
                        src={newToastPending}
                        alt=""
                        className="toast-icon"
                      />
                    )}
                    {type === toastTypes.success && (
                      <img
                        src={newToastSuccess}
                        alt=""
                        className="toast-icon"
                      />
                    )}
                    <button className="close-btn" onClick={dismissToast}>
                      <img
                        src={newToastClose}
                        alt="close toast"
                        className="close-btn-icon"
                      />
                    </button>
                  </div>
                  <h3 className="toast-heading">{heading ?? ''}</h3>
                  <p className="toast-description">{description ?? ''}</p>
                </div>
              </div>
            </div>
          </div>
        </Portal>
      )}
    </>
  );
}
