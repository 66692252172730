import { createSlice } from '@reduxjs/toolkit';
import toastTypes from '../../core/uikit/toast/toast-types';
import capitalizeFirst from '../../core/utils/capitalizeFirst.ts';
import { RootState } from '../../core/store/index.ts';


const initialState = {
  isOpen: false,
  type: toastTypes.error,
  heading: 'Error',
  description: 'This action can not be performed',
};

const toastSlice = createSlice({
  name: 'toastV2',
  initialState,
  reducers: {
    showToast(state, { payload }) {
      // console.log("toast", payload)
      state.type = payload.type;
      state.heading = payload.heading ?? payload.name ?? '';
      state.description = capitalizeFirst(
        payload.description ?? payload.desc ?? 'something went wrong',
      );
      state.isOpen = true;
    },
    closeToast(state) {
      state.isOpen = false;
      state.heading = '';
      state.description = '';
      state.type = '';
    },
  },
});

export default toastSlice.reducer;
export const { showToast, closeToast } = toastSlice.actions;

export const selectToastV2 = (state: RootState) => state.toast;
