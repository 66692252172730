import Router from "./core/routes";
import { Provider } from 'react-redux';
import { store } from './core/store';

export default function App() {
    return (
        <div className="App">
            {/* <button onClick={() => {
                throw Error("Test error")
                }}>Break the world</button>; */}
            <Provider store={store}>
                <Router />
            </Provider>
        </div>
    )
}
