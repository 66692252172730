import './GetHelpComponent.css';
import {useNavigate} from "react-router-dom";
export default function GetHelpComponent({text, action, routePage, color}: {text: string, action: string, routePage: string, color?: string}){
    const router = useNavigate();
    const getHelp = () => {
        router(routePage);
    }
    return(
        <section className="get-help-component">
            <span style={{color:color || "#1c2127"}}>{text}</span>
            <span onClick={getHelp} className="button" style={{color:color || "#1c2127", textDecoration: "underline"}}>{action}</span>
        </section>
    )
}
