import React, { useEffect } from 'react';

export enum ThemeMode {
  LIGHT = 'light',
  DARK = 'dark',
}

const useTheme = (
  themeFromUrl?: ThemeMode
): { theme: ThemeMode; toggleTheme: (thm: ThemeMode) => void } => {
  const [theme, setTheme] = React.useState<ThemeMode>(
    themeFromUrl ?? getSavedTheme()
  );

  function applyTheme(): void {
    const savedTheme = getSavedTheme();
    setDocumentElementTheme(savedTheme);
    setDocumentThemeClass(savedTheme);
  }

  function toggleTheme(thm: ThemeMode): void {
    // eslint-disable-next-line no-debugger
    // debugger
    const newTheme = thm ?? getOppositeTheme(theme);
    setDocumentElementTheme(newTheme);
    setDocumentThemeClass(newTheme);
    setTheme(newTheme);
    persistTheme(newTheme);
  }

  function getSavedTheme(): ThemeMode {
    // Defaults to light theme if a theme isn't found
    return localStorage.getItem('theme') == ThemeMode.DARK ? ThemeMode.DARK: ThemeMode.LIGHT;
  }

  useEffect(() => {
    applyTheme();
  }, []);

  return { theme: getSavedTheme(), toggleTheme };
};

// this enables css class styling
function setDocumentThemeClass(_theme: string): void {
  document.documentElement.setAttribute('class', _theme);
}

function getOppositeTheme(theme: string): ThemeMode {
  return theme === ThemeMode.LIGHT ? ThemeMode.DARK : ThemeMode.LIGHT;
}

function setDocumentElementTheme(value: string) {
  document.documentElement.setAttribute('data-theme', value);
}

function persistTheme(value: ThemeMode) {
  localStorage.setItem('theme', value);
}

export default useTheme;
